import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import Loader from "../../components/loading/Loader";
import StepperProgress from "../../components/Stepper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WhiteBgButton } from "../../components/buttons/WhiteBgButton";
import { BlueBgButton } from "../../components/buttons/BlueBgButton";
import AdminApi from "../../api/AdminApi";
import { randomColors } from "../../utills";
import ShowToast from "../../components/ShowToast";
import { setGeographicalPieData } from "../../store/slices/userSlice";
import PieChartScreen from "../../components/pieChart";
import ETFSTable from "../../components/ETFSTable";

const GeographicalExposure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const targetData = useSelector(
    (state) => state.userData?.targetData?.common_geography_target
  );
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [pieData, setpieData] = useState([]);
  const [toast, showTost] = useState({});

  useEffect(() => {
    userInfo?._id && targetData?.US && fetchData();
  }, [userInfo?._id, targetData]);

  const fetchData = async () => {
    setloading(true);

    const res = await AdminApi.getGeographicalExposure(userInfo?._id);
    if (res?.status === 200) {
      const us = parseFloat(targetData?.US);
      const canada = parseFloat(targetData?.Canada);
      const europe = parseFloat(targetData?.Europe);
      const pacific = parseFloat(targetData?.Pacific);
      const emerging = parseFloat(targetData?.Emerging_Markets);
      const middle = parseFloat(targetData?.Middle_East);
      const other = parseFloat(targetData?.Other);

      const arrLength = res?.data?.geographical_exposure?.length;
      const adjustedObj = res?.data?.geographical_exposure[arrLength - 1];

      const targetDataObj = {
        Type: "-",
        Ticker: "Target",
        Value: "-",
        US: us,
        Canada: canada,
        Europe: europe,
        Pacific: pacific,
        "Emerging Markets": emerging,
        "Middle East": middle,
        Other: other,
      };

      const gapObj = {
        Type: "-",
        Ticker: "Gap",
        Value: "-",
        US: adjustedObj?.US - us,
        Canada: adjustedObj?.Canada - canada,
        Europe: adjustedObj?.Europe - europe,
        Pacific: adjustedObj?.Pacific - pacific,
        "Emerging Markets": adjustedObj?.["Emerging Markets"] - emerging,
        "Middle East": adjustedObj?.["Middle East"] - middle,
        Other: adjustedObj?.Other - other,
      };

      setdata([...res?.data?.geographical_exposure, targetDataObj, gapObj]);
    } else {
      showTost({
        show: true,
        text: "No data to show!",
        type: "error",
      });
      setdata([]);
    }

    const response = await AdminApi.getGeographyPie(userInfo?._id);
    if (response.status === 200) {
      const pieArr = [];
      Object.entries(response.data).map(([key, value], i) => {
        let obj;
        obj = {
          label: key,
          value: value,
          color: randomColors(key),
        };
        pieArr.push(obj);
      });
      setpieData(pieArr);
      dispatch(setGeographicalPieData(pieArr));
    }
    setloading(false);
  };

  return (
    <div className={styles.container}>
      <ShowToast toast={toast} showTost={showTost} />
      {loading && <Loader />}
      <Header />
      <p className={styles.name}>{userInfo?.client_name}</p>
      <StepperProgress step={2} />

      <div className={styles.subContainer}>
        {data?.length > 0 && (
          <ETFSTable data={data} red={true} type="Country" />
        )}
        {pieData?.length > 0 && <PieChartScreen pieData={pieData} />}

        <div className={styles.buttonFlex}>
          <WhiteBgButton
            title={"Back to ETFS"}
            onClick={() => {
              navigate(`/admin/etfs?form_id=${userInfo?._id}`);
            }}
          />
          <BlueBgButton
            title={"Go to Sector Breakdown"}
            onClick={() => {
              navigate(`/admin/sector-breakdown?form_id=${userInfo?._id}`);
            }}
            customStyle={{ paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

export default GeographicalExposure;
