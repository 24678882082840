import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormsData, setInputData } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { mainData } from "../../utills";
import LoginScreen from "../../components/login";

const randomId = Math.random().toString(36).substring(3, 9);

const Investor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData);
  const [name, setname] = useState(userData?.payload?.name ?? "");
  const [email, setemail] = useState(userData?.payload?.email ?? "");
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsg1, setErrorMsg1] = useState(null);

  // console.log("kkkkkk", userData);

  const handleSubmit = useCallback(async () => {
    if (name === "") {
      setErrorMsg("Please enter your name!");
      return;
    } else if (email === "") {
      setErrorMsg1("Please enter your email!");
      return;
    } else if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg1("Email is not valid!");
      return;
    }
    let payload;
    if (email === userData?.payload?.email) {
      payload = {
        ...userData?.payload,
        name: name,
        email: email,
        uniqueId: userData?.uniqueId,
      };
    } else {
      payload = {
        name: name,
        email: email,
        uniqueId: randomId,
      };
      dispatch(setFormsData(mainData));
    }
    dispatch(setInputData(payload));
    navigate(
      `/${email === userData?.payload?.email ? userData?.uniqueId : randomId}`
    );
  }, [name, email, userData, randomId]);

  return (
    <LoginScreen
      title={"Hello, Investor!"}
      name={name}
      setname={setname}
      setErrorMsg={setErrorMsg}
      errorMsg={errorMsg}
      email={email}
      setemail={setemail}
      setErrorMsg1={setErrorMsg1}
      errorMsg1={errorMsg1}
      handleSubmit={handleSubmit}
    />
  );
};

export default Investor;
