import React, { useEffect } from "react";
import image from "../../assets/submit.png";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BlueBgButton } from "../../components/buttons";

const SubmittedScreen = () => {
  const navigate = useNavigate();
  const isSubmitted = useSelector((state) => state.userData?.isFormSubmitted);

  useEffect(() => {
    if (!isSubmitted) {
      navigate("/");
    }
  }, [isSubmitted]);

  return (
    <div className={styles.container}>
      <img src={image} alt="n/a" className={styles.img} />
      <p className={styles.title}>Thank You!</p>
      <p className={styles.des}>
        Our team have started analysing your data. We will send you the report
        at your email address as soon as the assessment is done
      </p>
      <BlueBgButton
        title={"Start Another Portfolio"}
        onClick={() => navigate("/")}
        customStyle={{
          borderRadius: 42,
          marginTop: 25,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      />
    </div>
  );
};

export default SubmittedScreen;
