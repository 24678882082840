import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import Loader from "../../components/loading/Loader";
import StepperProgress from "../../components/Stepper";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { WhiteBgButton } from "../../components/buttons/WhiteBgButton";
import { BlueBgButton } from "../../components/buttons/BlueBgButton";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import ETFSTable from "../../components/ETFSTable";

const ETFS = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const [loading, setloading] = useState(false);
  const [selectedTab, setselectedTab] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [industryData, setIndustryData] = useState([]);

  useEffect(() => {
    userInfo?._id && fetchData();
  }, [userInfo?._id]);

  const fetchData = async () => {
    setloading(true);
    const res = await AdminApi.getETFSCountry(userInfo._id);
    const response = await AdminApi.getETFSIndustry(userInfo._id);
    // const sector = await AdminApi.getSector(userInfo?._id);
    // console.log("=======", response);
    if (res?.status === 200 && response?.status === 200) {
      setCountryData(res?.data?.countries);
      setIndustryData(response?.data?.industries);
    }
    setloading(false);
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <Header />
      <p className={styles.name}>{userInfo?.client_name}</p>
      <StepperProgress step={1} />

      <div className={styles.subContainer}>
        <Stack direction="row">
          <div
            className={
              selectedTab === 0 ? styles.balance : styles.balanceInactive
            }
            onClick={() => setselectedTab(0)}
          >
            Country
          </div>
          <div
            className={
              selectedTab === 1 ? styles.allocation : styles.allocationInactive
            }
            onClick={() => setselectedTab(1)}
          >
            Industry
          </div>
        </Stack>

        {selectedTab === 0 && countryData?.length > 0 && (
          <ETFSTable data={countryData} type="CountryWithoutHeader" />
        )}
        {selectedTab === 1 && industryData?.length > 0 && (
          <ETFSTable data={industryData} type="IndustryWithoutHeader" />
        )}

        <div className={styles.buttonFlex}>
          <WhiteBgButton
            title={"Back to Input"}
            onClick={() => navigate(`/admin/input?form_id=${userInfo?._id}`)}
          />
          <BlueBgButton
            title={"Go to Geographical Exposure"}
            onClick={() =>
              navigate(`/admin/geographical-exposure?form_id=${userInfo?._id}`)
            }
            customStyle={{ paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

export default ETFS;
