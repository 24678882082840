import React, { memo } from "react";
import styles from "./InputTable/styles.module.scss";

const industryHeader = [
  "Type",
  "Ticker",
  "Value",
  "Financials",
  "Energy",
  "Basic Materials",
  "Industrials",
  "Technology",
  "Consumer Discretionary",
  "Telecommunications",
  "Utilities",
  "Consumer Staples",
  "Health Care",
  "Real Estate",
  "Cash",
];
const countryHeader = [
  "Type",
  "Ticker",
  "Value",
  "US",
  "Canada",
  "Europe",
  "Pacific",
  "Emerging Markets",
  "Middle East",
  "Other",
];

const ETFSTable = memo(({ data, red = false, type }) => {
  // console.log("data", data);
  const headers =
    type === "Country"
      ? countryHeader
      : type === "CountryWithoutHeader"
      ? countryHeader.filter((e) => e !== "Value")
      : type === "IndustryWithoutHeader"
      ? industryHeader.filter((e) => e !== "Value")
      : industryHeader;

  const applyStyle = (value, isLastRow) => {
    if (typeof value === "number") {
      if (isLastRow && red) {
        if (value > 2 || value < -2) {
          return {
            backgroundColor: "rgb(242 221 221)",
            color: "red",
            textAlign: "center",
          };
        } else {
          return {
            backgroundColor: "#fff",
            color: "#000",
            textAlign: "center",
          };
        }
      } else if (value > 0) {
        return {
          backgroundColor: "#C6EFCD",
          color: "#126D12",
          textAlign: "center",
        };
      }
    }
    return { textAlign: "center" };
  };

  return (
    <div className={styles.overflow}>
      <table className={styles.table}>
        <tbody>
          <tr>
            {headers.map((a, b) => (
              <th key={b}>{a}</th>
            ))}
          </tr>
          {data.map((item, index, row) => (
            <tr key={index}>
              {headers.map((header) => (
                <td
                  key={header}
                  style={applyStyle(item[header], index + 1 === row.length)}
                >
                  {isNaN(item[header])
                    ? item[header]
                    : parseFloat(item[header]).toFixed(2)}
                  {!isNaN(item[header]) && item[header] !== "-" && "%"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ETFSTable;
