import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { CustomButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import ShowToast from "../../components/ShowToast";
import { useSelector } from "react-redux";

const NotFound = () => {
  const navigate = useNavigate();
  const loginStatus = useSelector((state) => state?.userData?.loginStatus);
  const [toast, showTost] = useState({});

  useEffect(() => {
    if (!navigator.onLine || navigator.onLine === "offline") {
      showTost({
        show: true,
        text: "Please check your internet connection",
        type: "error",
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <ShowToast toast={toast} showTost={showTost} />
      <img
        alt="n/a"
        src={require("../../assets/onboarding.png")}
        className={styles.img}
      />
      <p className={styles.notFound}>Page Not Found</p>
      <CustomButton
        title="Go To Home"
        onClick={() => navigate(loginStatus ? "/admin" : "/")}
      />
    </div>
  );
};

export default NotFound;
