import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import bottomImg from "../../assets/bottomLogo.png";
import styles from "./styles.module.scss";
import { BackArrow } from "../../assets/svg";
import RadioButtonsGroup from "../../components/RadioGroup";
import { CustomButton } from "../../components/buttons";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { setFormsData, setIsFormSubmitted } from "../../store/slices/userSlice";
import ShowToast from "../../components/ShowToast";
import { SelectScreen } from "../../components/SelectScreen";
import {
  americanArr,
  canadaAndDualCityzenArr,
  submitFormsHandle,
  childIsAmericanArr,
  childIsCanadaOrDual,
} from "../../utills";
import Loader from "../../components/loading/Loader";
import UserApi from "../../api/UserApi";

const InputForms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData);
  const uniqueId = userData?.uniqueId;
  const formsData = userData.formsData;
  const newArr = JSON.parse(JSON.stringify(formsData));
  const location = useLocation();
  const pathName = location.pathname.slice(1);
  const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
  const [toast, showTost] = useState({});
  const [error, seterror] = useState(null);
  const [disabled, setdisabled] = useState(false);
  const [resetData, setResetData] = useState(false);
  const [loading, setloading] = useState(false);
  const [tickers, setTickers] = useState([]);

  // console.log(">>>>>>>>>", newArr);

  useEffect(() => {
    //to access this page user must have unique id and uniques id should be same as url path
    if (!uniqueId || uniqueId !== pathName || formsData?.length <= 0) {
      navigate("*");
    }
  }, [uniqueId]);

  useEffect(() => {
    if (currentQuestionIndex === 0 && resetData) {
      const slice = newArr.slice(0, 1);
      if (
        newArr?.[0]?.data?.[0]?.answer === "I’m American" &&
        newArr?.[0]?.data?.[1]?.answer !== "Child’s Education"
      ) {
        const mainArrUpdate = [...slice, ...americanArr];
        dispatch(setFormsData(mainArrUpdate));
      } else if (
        newArr?.[0]?.data?.[0]?.answer === "I’m American" &&
        newArr?.[0]?.data?.[1]?.answer === "Child’s Education"
      ) {
        const mainArrUpdate = [...slice, ...childIsAmericanArr];
        dispatch(setFormsData(mainArrUpdate));
      } else if (
        newArr?.[0]?.data?.[0]?.answer !== "I’m American" &&
        newArr?.[0]?.data?.[1]?.answer === "Child’s Education"
      ) {
        const mainArrUpdate = [...slice, ...childIsCanadaOrDual];
        dispatch(setFormsData(mainArrUpdate));
      } else {
        const mainArrUpdate = [...slice, ...canadaAndDualCityzenArr];
        dispatch(setFormsData(mainArrUpdate));
      }
    }
  }, [
    currentQuestionIndex,
    newArr?.[0]?.data?.[0]?.answer,
    newArr?.[0]?.data?.[1]?.answer,
    resetData,
  ]);

  useEffect(() => {
    if (
      formsData.length - 1 === currentQuestionIndex &&
      formsData?.length > 2
    ) {
      if (formsData[formsData.length - 1]?.data?.[0]?.answer !== "I Accept") {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    }
  }, [
    currentQuestionIndex,
    formsData[formsData.length - 1]?.data?.[0]?.answer,
  ]);

  useEffect(() => {
    fetchTickerData();
  }, []);

  const fetchTickerData = async () => {
    setloading(true);
    const res = await UserApi.getTicker();
    if (res?.status === 200) {
      setTickers(res.data.tickers);
    }
    setloading(false);
  };

  const handleSubmit = async () => {
    if (disabled) {
      return;
    }

    //if required
    const requiredArr = formsData[currentQuestionIndex].data.filter(
      (item) => item.answer === "" && item.isRequired
    );

    //if additional is required
    const requiredAdditionalArr =
      formsData[currentQuestionIndex].data?.[0]?.answer !== "No" &&
      formsData[currentQuestionIndex].data?.[0]?.additionalData?.length > 0
        ? formsData[currentQuestionIndex].data?.[0]?.additionalData.filter(
            (item) => item.answer === "" && item.isRequired
          )
        : [];

    if (error !== "" && error !== null) {
      showTost({
        show: true,
        text: error,
        type: "error",
      });
      return;
    }
    if (requiredArr?.length > 0 || requiredAdditionalArr?.length > 0) {
      showTost({
        show: true,
        text: "Please fill up all required data",
        type: "error",
      });
      return;
    }
    if (formsData.length === currentQuestionIndex + 1) {
      await submitFormsHandle(formsData, userData);
      //submit data
      setloading(true);
      setdisabled(false);
      const payload = await submitFormsHandle(formsData, userData);

      console.log("pppppppppp", payload);
      const res = await UserApi.setClientData(payload);
      if (res?.status === 200 || res?.status === 201) {
        dispatch(setIsFormSubmitted({ status: true }));
        navigate("/submitted");
        setloading(false);
      } else {
        setloading(false);
        showTost({
          show: true,
          text: "Something went wrong! Please try again or later.",
          type: "error",
        });
      }
    } else {
      setdisabled(false);
      const answer = formsData[currentQuestionIndex].data?.[0]?.answer;
      const skip = answer === "No" ? 2 : 1;
      setcurrentQuestionIndex(currentQuestionIndex + skip);
    }
  };

  const backButtonClick = () => {
    setdisabled(false);
    if (currentQuestionIndex === 0) {
      navigate("/");
    } else {
      const skipped =
        formsData[currentQuestionIndex - 1].data?.[0]?.answers?.[0]?.name;
      setcurrentQuestionIndex(
        (current) => current - (skipped === null ? 2 : 1)
      );
    }
  };

  const handleChange = async (e, childKey, additionalIndex = null) => {
    if (childKey === 2 && (parseFloat(e) > 60 || parseFloat(e) < 1)) {
      showTost({
        show: true,
        text: "The remaining years will not exceed 60 and will not be less than 0.",
        type: "error",
      });
      return;
    }
    const prevAnswer = newArr?.[0]?.data?.[0]?.answer;
    const prevAnswer1 = newArr?.[0]?.data?.[1]?.answer;
    if ((prevAnswer !== e || prevAnswer1 !== e) && currentQuestionIndex === 0) {
      setResetData(true);
    } else {
      setResetData(false);
    }
    if (additionalIndex !== null) {
      newArr[currentQuestionIndex].data[childKey].additionalData.map(
        (item, index) => ({
          ...item,
          answer: index === additionalIndex ? (item.answer = e) : item.answer,
        })
      );
    } else {
      if (
        e === "No" &&
        newArr[currentQuestionIndex].data[childKey]?.additionalData?.length > 0
      ) {
        newArr[currentQuestionIndex].data[0].additionalData.map((item) => ({
          ...item,
          changeItem: item.answer ? (item.answer = "") : item.answer,
        }));
      }
      newArr[currentQuestionIndex].data.map((item, index) => ({
        ...item,
        answer: index === childKey ? (item.answer = e) : item.answer,
      }));
    }
    dispatch(setFormsData(newArr));
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <img src={bottomImg} className={styles.img} alt="n/a" />
      <div style={{ paddingBottom: 50 }}>
        <ShowToast toast={toast} showTost={showTost} />
        <div
          className={styles.titleContainer}
          onClick={() => backButtonClick()}
        >
          <BackArrow />
          <p className={styles.back}>Back</p>
        </div>

        {formsData?.[currentQuestionIndex].data?.length > 0 &&
          formsData[currentQuestionIndex].data.map((item, childKey) => (
            <div key={childKey + item.id} style={{ marginBottom: 40 }}>
              {item.header && <p className={styles.header}>{item.header}</p>}
              {item.title && (
                <p className={styles.title}>
                  {item.title}
                  {item?.isRequired ? "*" : ""}
                </p>
              )}
              {item.subTitle && (
                <p className={styles.subTitle}>{item.subTitle}</p>
              )}
              {item.description && (
                <p className={styles.des}>{item.description}</p>
              )}

              {item.type === "select" && (
                <p className={styles.des} style={{}}>
                  Something missing? Email{" "}
                  <span
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      const subject = encodeURIComponent("");
                      const sender = encodeURIComponent(
                        "support@bridgetcasey.com"
                      );
                      const body = encodeURIComponent(``);
                      const url = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&to=${sender}&body=${body}`;
                      window.open(url, "_blank");
                    }}
                  >
                    support@bridgetcasey.com
                  </span>
                </p>
              )}

              {item.type === "radio" && (
                <RadioButtonsGroup
                  item={item}
                  childKey={childKey}
                  handleChange={handleChange}
                />
              )}

              {item.answer === "Yes" && item?.additionalData?.length > 0
                ? item.additionalData.map(
                    (additionalItem, additionalIndex) =>
                      additionalItem.type === "input" && (
                        <div
                          key={additionalIndex + additionalItem.id}
                          style={{ marginBottom: 40 }}
                        >
                          <p className={styles.title}>
                            {additionalItem.title}
                            {additionalItem?.isRequired ? "*" : ""}
                          </p>
                          <p className={styles.des}>
                            {additionalItem.description}
                          </p>
                          <TextField
                            type={additionalItem.inputType}
                            InputProps={{ inputProps: { min: 0 } }}
                            id="outlined-controlled"
                            label={additionalItem.placeholder}
                            className={styles.input}
                            value={additionalItem.answer}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                childKey,
                                additionalIndex
                              )
                            }
                          />
                        </div>
                      )
                  )
                : null}

              {item.type === "input" && (
                <TextField
                  type={item.inputType}
                  InputProps={{ inputProps: { min: 0 } }}
                  id="outlined-controlled"
                  label={item.placeholder}
                  className={styles.input}
                  value={item.answer}
                  onChange={(e) => handleChange(e.target.value, childKey)}
                />
              )}

              {item.type === "select" && (
                <SelectScreen
                  childKey={childKey}
                  currentQuestionIndex={currentQuestionIndex}
                  newArr={newArr}
                  dispatch={dispatch}
                  seterror={seterror}
                  setdisabled={setdisabled}
                  tickers={tickers}
                  fetchTickerData={fetchTickerData}
                />
              )}

              {item.type === "textArea" && (
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 0, width: 660 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    label="Enter Your Concern"
                    multiline
                    rows={8}
                    value={item.answer}
                    onChange={(e) => handleChange(e.target.value, childKey)}
                  />
                </Box>
              )}
            </div>
          ))}

        <CustomButton
          title={
            formsData[currentQuestionIndex]?.data?.[0]?.submitButton ?? "NEXT"
          }
          onClick={() => handleSubmit()}
          disbaled={disabled}
          customStyle={{
            paddingLeft: formsData[currentQuestionIndex]?.data?.[0]
              ?.submitButton
              ? 60
              : 130,
            paddingRight: formsData[currentQuestionIndex]?.data?.[0]
              ?.submitButton
              ? 60
              : 130,
            paddingTop: 15,
            paddingBottom: 15,
          }}
        />
      </div>
    </div>
  );
};

export default InputForms;
