import styles from "./styles.module.scss";

export const CustomButton = ({ title, onClick, customStyle, disbaled }) => {
  return (
    <div
      className={disbaled ? styles.customButtonDisabled : styles.customButton}
      style={customStyle}
      onClick={onClick}
      type="submit"
    >
      {title}
    </div>
  );
};
