import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function RadioButtonsGroup({ item, childKey, handleChange }) {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={item.answer}
        onChange={(e) => handleChange(e.target.value, childKey)}
      >
        {item.options.map((item, key) => (
          <FormControlLabel
            key={key}
            value={item.name}
            control={<Radio />}
            label={item?.name ?? item.name}
            style={{ paddingRight: 50 }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
