const colorsPlate = {
  aqua: "#00ffff",
  azure: "#f0ffff",
  beige: "#f5f5dc",
  black: "#000000",
  blue: "#0000ff",
  brown: "#a52a2a",
  cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgrey: "#a9a9a9",
  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkviolet: "#9400d3",
  fuchsia: "#ff00ff",
  gold: "#ffd700",
  green: "#008000",
  indigo: "#4b0082",
  khaki: "#f0e68c",
  lightblue: "#add8e6",
  // lightcyan: "#e0ffff",
  lightgreen: "#90ee90",
  lightgrey: "#d3d3d3",
  lightpink: "#ffb6c1",
  lightyellow: "#ffffe0",
  lime: "#00ff00",
  magenta: "#ff00ff",
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  orange: "#ffa500",
  pink: "#ffc0cb",
  purple: "#800080",
  violet: "#800080",
  red: "#ff0000",
  silver: "#c0c0c0",
  yellow: "#ffff00",
};

export const randomColors = (i) => {
  var result;
  var count = 0;
  for (var prop in colorsPlate)
    if (Math.random() < 1 / ++count) result = colorsPlate[prop];
  let color;
  switch (i) {
    case "US":
      color = "#6DD1CB";
      break;
    case "Canada":
      color = "#3CA6B7";
      break;
    case "Europe":
      color = "#1A7BA0";
      break;
    case "Pacific":
      color = "#135280";
      break;
    case "Emerging Markets":
      color = "#17295A";
      break;
    case "Middle East":
      color = "#E234BC";
      break;
    case "Other":
      color = "#936091";
      break;

    case "Financials":
      color = "#6DD1CB";
      break;
    case "Energy":
      color = "#3CA6B7";
      break;
    case "Basic Materials":
      color = "#1A7BA0";
      break;
    case "Industrials":
      color = "#135280";
      break;
    case "Technology":
      color = "#17295A";
      break;
    case "Consumer Discretionary":
      color = "#584378";
      break;
    case "Telecommunications":
      color = "#936091";
      break;
    case "Utilities":
      color = "#CA82A4";
      break;
    case "Consumer Staples":
      color = "#FBA9B6";
      break;
    case "Health Care":
      color = "#DC699F";
      break;
    case "Real Estate":
      color = "#CE73CA";
      break;
    case "Cash":
      color = "#9A3E96";
      break;

    default:
      color = result;
  }
  return color;
};
