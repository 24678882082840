export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5172 8.38113C18.8479 8.78651 18.8243 9.38375 18.4463 9.76215L12.604 15.6041C12.4081 15.8 12.1461 15.906 11.8744 15.906C11.6025 15.906 11.341 15.8002 11.1444 15.6041L5.3023 9.76203C4.89923 9.35848 4.89923 8.70527 5.3023 8.30278C5.70551 7.89909 6.35889 7.89909 6.76188 8.30266L11.8744 13.4147L16.9867 8.30278C17.3647 7.92434 17.9626 7.90067 18.368 8.23178L18.4464 8.30274L18.5172 8.38113Z"
        fill="#333333"
      />
    </svg>
  );
};
