import React, { memo } from "react";
import styles from "./styles.module.scss";
import { Avatar, Grid, Stack } from "@mui/material";
import canada from "../../assets/canada.png";
import usa from "../../assets/usa.png";
import { Done, NotDone, Start } from "../../assets/svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/slices/userSlice";
import { WhiteBgButton } from "../buttons/WhiteBgButton";
import moment from "moment";
import { getColors } from "../../utills";

export const UserLists = memo(({ props, loading, handleDownload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = props?.data ?? [];
  // console.log(".........", data);

  if (loading) {
    return <div></div>;
  }

  if (data?.length > 0) {
    return data?.map((item, index) => {
      // console.log(">>>>>>>", item?.assessment_status);
      return (
        <div className={styles.container} key={index}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 2, sm: 12, md: 24 }}
            style={{ alignItems: "center" }}
          >
            <Grid item xs={3} sm={4} md={5}>
              <Stack direction="row" style={{ alignItems: "center" }}>
                <Avatar sx={{ bgcolor: getColors(index) }}>
                  <p className={styles.avatar}>{item?.client_name.charAt(0)}</p>
                </Avatar>
                <div style={{ paddingLeft: 15 }}>
                  <p className={styles.name} style={{ paddingBottom: 3 }}>
                    {item?.client_name}
                  </p>
                  <p className={styles.email}>{item?.client_email}</p>
                </div>
              </Stack>
            </Grid>

            <Grid item xs={3} sm={4} md={4}>
              <p className={styles.name}>Tax Residency</p>
              <Stack direction="row" style={{ alignItems: "center" }}>
                <img
                  src={item?.tax_residency === "CANADIAN" ? canada : usa}
                  height={18}
                  width={26}
                  alt="n/a"
                />
                <p className={styles.email} style={{ paddingLeft: 10 }}>
                  {item?.tax_residency === "CANADIAN"
                    ? "Canada"
                    : item?.tax_residency === "AMERICAN"
                    ? "USA"
                    : "Dual Citizen"}
                </p>
              </Stack>
            </Grid>

            <Grid item xs={3} sm={4} md={4}>
              <p className={styles.name}>Portfolio Type</p>
              <p className={styles.email}>
                {item?.portfolio_type === "RETIREMENT"
                  ? "Retirement"
                  : item?.portfolio_type === "CHILD_EDUCATION"
                  ? "Child’s Education"
                  : "General Investment"}
              </p>
            </Grid>

            <Grid item xs={3} sm={4} md={4}>
              <p className={styles.name}>Assessment Status</p>
              <Stack direction="row" style={{ alignItems: "center" }}>
                {item?.assessment_status === "Done" ||
                item?.assessment_status === "DONE" ? (
                  <Done />
                ) : (
                  <NotDone />
                )}
                <p
                  className={
                    item?.assessment_status === "DONE" ||
                    item?.assessment_status === "Done"
                      ? styles.done
                      : styles.notDone
                  }
                  style={{ paddingLeft: 10 }}
                >
                  {item?.assessment_status === "DONE" ||
                  item?.assessment_status === "Done"
                    ? "Done"
                    : "Not Done"}
                </p>
              </Stack>
            </Grid>

            <Grid item xs={3} sm={4} md={4}>
              <p className={styles.name}>Submission Date</p>
              <p className={styles.email}>
                {moment.utc(item?.created_at).local().format("DD/MM/YYYY")}
              </p>
            </Grid>

            <Grid item xs={3} sm={4} md={3}>
              {item?.assessment_status === "NOT_STARTED_YET" ? (
                <div
                  onClick={() => {
                    const payload = { ...item };
                    dispatch(setUserInfo(payload));
                    navigate(`/admin/summary?form_id=${item?._id}`);
                  }}
                >
                  <Stack direction="row" className={styles.start}>
                    <p>Start</p>
                    <Start />
                  </Stack>
                </div>
              ) : (
                <>
                  <div
                    className={styles.download}
                    onClick={() => handleDownload(item?._id)}
                  >
                    Download PDF
                  </div>
                  <WhiteBgButton
                    title="Review"
                    onClick={() => {
                      const payload = { ...item };
                      dispatch(setUserInfo(payload));
                      navigate(`/admin/summary?form_id=${item?._id}`);
                    }}
                    customStyle={{
                      fontSize: 12,
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      marginTop: 15,
                      marginLeft: 8,
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </div>
      );
    });
  }
  return <div className={styles.noData}>No Data Found!</div>;
});
