import { createSlice } from "@reduxjs/toolkit";
import { mainData } from "../../utills/FormsData";

const initialState = {
  loginStatus: false,
  payload: {},
  uniqueId: null,
  formsData: mainData,
  isFormSubmitted: false,
  userInfo: {},
  summary: {},
  geographicalPieData: [],
  sectorPieData: [],
  calculatorVisited: false,
  bridgetsNoteVisited: false,
  isSubmitted: false,
  targetData: {},
  stepVisited: [],
};

export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.loginStatus = false;
      state.payload = {};
      state.uniqueId = null;
      state.formsData = mainData;
      state.isFormSubmitted = false;
      state.userInfo = {};
      state.summary = {};
      state.geographicalPieData = [];
      state.sectorPieData = [];
      state.calculatorVisited = false;
      state.bridgetsNoteVisited = false;
      state.isSubmitted = false;
      state.targetData = {};
      state.stepVisited = [];
    },

    loginAction: (state, action) => {
      state.loginStatus = true;
      state.token = action.payload.token;
      state.refresh_token = action.payload.refresh_token;
    },
    setInputData: (state, action) => {
      if (action.payload?.uniqueId) {
        state.uniqueId = action.payload?.uniqueId;
      }
      delete action.payload?.uniqueId;
      state.payload = action.payload;
    },
    setFormsData: (state, action) => {
      state.formsData = action.payload;
      state.isFormSubmitted = false;
    },
    setIsFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload.status;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      state.stepVisited = [];
      state.summary = {};
      state.geographicalPieData = [];
      state.sectorPieData = [];
      state.calculatorVisited = false;
      state.bridgetsNoteVisited = false;
      state.isSubmitted = false;
    },
    setUserSummary: (state, action) => {
      state.summary = action.payload;
    },
    setGeographicalPieData: (state, action) => {
      state.geographicalPieData = action.payload;
    },
    setSectorPieData: (state, action) => {
      state.sectorPieData = action.payload;
    },
    setCalculatorVisited: (state) => {
      state.calculatorVisited = true;
    },
    setBridgetsVisited: (state) => {
      state.bridgetsNoteVisited = true;
    },
    setIsSubmitted: (state) => {
      state.isSubmitted = true;
    },
    setTargetData: (state, action) => {
      state.targetData = action.payload;
    },
    setStepVisited: (state, action) => {
      state.stepVisited = action.payload;
    },
  },
});

export const {
  loginAction,
  logoutAction,
  setInputData,
  setFormsData,
  setIsFormSubmitted,
  setUserInfo,
  setUserSummary,
  setGeographicalPieData,
  setSectorPieData,
  setCalculatorVisited,
  setBridgetsVisited,
  setIsSubmitted,
  setTargetData,
  setStepVisited,
} = userSlice.actions;

export default userSlice.reducer;
