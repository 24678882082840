import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import Header from "../../components/header";
import Loader from "../../components/loading/Loader";
import StepperProgress from "../../components/Stepper";
import { Stack } from "@mui/material";
import { Start } from "../../assets/svg";
import { Allocation, BalanceContribution } from "../../components/InputTable";
import { useSelector } from "react-redux";

const InputScreen = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const id = userInfo?._id;
  const name = userInfo?.client_name;
  const [data, setdata] = useState();
  const [loading, setloading] = useState(false);
  const [selectedTab, setselectedTab] = useState(0);

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      navigate("/admin/users");
    }
  }, [id]);

  const fetchData = async () => {
    setloading(true);
    const res = await AdminApi.getInput(id);
    if (res?.status === 200) {
      setdata(res?.data);
    }
    setloading(false);
  };
  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <Header />
      <p className={styles.name}>{name}</p>
      <StepperProgress step={0} />

      <div className={styles.subContainer}>
        <Stack direction="row">
          <div
            className={
              selectedTab === 0 ? styles.balance : styles.balanceInactive
            }
            onClick={() => setselectedTab(0)}
          >
            Balance & Contribution
          </div>
          <div
            className={
              selectedTab === 1 ? styles.allocation : styles.allocationInactive
            }
            onClick={() => setselectedTab(1)}
          >
            Allocation
          </div>
        </Stack>

        {selectedTab === 0 && (
          <BalanceContribution data={data?.balance_and_contribution} />
        )}

        {selectedTab === 1 && <Allocation data={data?.allocation} />}

        <div className={styles.bottomContainer}>
          {/* {selectedTab === 0 ? (
            <div className={styles.bottomText}>
              TFSA Breakdown Equation: TFSA Balance/ (TFSA Balance + RRSP
              Balance)
            </div>
          ) : (
            <p className={styles.bottomText}>
              Total Cash Equation: (TFSA Breakdown * TFSA Cash) +(RRSP Breakdown
              * RRSP Cash)
            </p>
          )} */}
          <Stack direction="row" className={styles.buttonContainer}>
            <div
              className={styles.backButton}
              onClick={() =>
                navigate(`/admin/summary?form_id=${userInfo?._id}`)
              }
            >
              Back to Summary
            </div>

            <div
              onClick={() => {
                navigate(`/admin/etfs?form_id=${userInfo?._id}`);
              }}
            >
              <Stack direction="row" className={styles.start}>
                <p>Check ETFS</p>
                <Start />
              </Stack>
            </div>
          </Stack>
        </div>
        {/* {selectedTab === 1 && (
          <p className={styles.bottomText} style={{ paddingTop: 10 }}>
            Total Cash Equation: (45.5%* 5%) +(54.5% * 5%)=5%{" "}
          </p>
        )} */}
      </div>
    </div>
  );
};

export default InputScreen;
