import http from "./NetworkClient";

export default class UserApi {
  static async setClientData(payload) {
    const response = await http.post("/dev/client", payload);
    return response;
  }
  static async getTicker() {
    const response = await http.get("/dev/tickers");
    return response;
  }
  static async setTicker(payload) {
    const response = await http.post("/dev/tickers", payload);
    return response;
  }
}
