import AdminApi from "../api/AdminApi";

export const CalculatorAndGraph = async (
  periods,
  amount,
  interestRate,
  deposit,
  radioVal,
  setfutureValue,
  setYear,
  setFutureValueWithoutDeposit,
  id,
  showTost
) => {
  const totalPeriods = parseFloat(periods);
  const startingAmount = parseFloat(amount);
  const interestRateFloat = parseFloat(interestRate);
  const monthlyDeposit = parseFloat(deposit);
  const endBalanceAmount =
    startingAmount * (interestRateFloat / 100) + startingAmount;

  if (totalPeriods > 60) {
    showTost({
      show: true,
      text: "The number of periods cannot exceed 60",
      type: "error",
    });
    return;
  }
  if (endBalanceAmount > 250000000000) {
    showTost({
      show: true,
      text: "The input values are outside the allowable range.",
      type: "error",
    });
    return;
  }

  let yearSplit = [];

  let futureValue = [];
  let endBalance;
  let interest;

  let futureValueWithoutDeposit = [];
  let endBalanceWithoutDeposit;

  if (radioVal === "BEGINNING") {
    for (let i = 1; i <= totalPeriods; i++) {
      yearSplit.push("Year " + i);
      if (i === 1) {
        interest =
          (startingAmount + monthlyDeposit) * (interestRateFloat / 100);
        endBalance = startingAmount + monthlyDeposit + interest;
        futureValue.push(endBalance.toFixed(2));

        endBalanceWithoutDeposit =
          startingAmount * (interestRateFloat / 100) + startingAmount;
        futureValueWithoutDeposit.push(endBalanceWithoutDeposit.toFixed(2));
      } else {
        interest = (endBalance + monthlyDeposit) * (interestRateFloat / 100);
        endBalance = endBalance + monthlyDeposit + interest;
        futureValue.push(endBalance.toFixed(2));

        endBalanceWithoutDeposit =
          endBalanceWithoutDeposit * (interestRateFloat / 100) +
          endBalanceWithoutDeposit;
        futureValueWithoutDeposit.push(endBalanceWithoutDeposit.toFixed(2));
      }
    }
  } else {
    for (let i = 1; i <= totalPeriods; i++) {
      yearSplit.push("Year " + i);
      if (i === 1) {
        interest = startingAmount * (interestRateFloat / 100);
        endBalance = startingAmount + monthlyDeposit + interest;
        futureValue.push(endBalance.toFixed(2));

        endBalanceWithoutDeposit =
          startingAmount * (interestRateFloat / 100) + startingAmount;
        futureValueWithoutDeposit.push(endBalanceWithoutDeposit.toFixed(2));
      } else {
        interest = endBalance * (interestRateFloat / 100);
        endBalance = endBalance + monthlyDeposit + interest;
        futureValue.push(endBalance.toFixed(2));

        endBalanceWithoutDeposit =
          endBalanceWithoutDeposit * (interestRateFloat / 100) +
          endBalanceWithoutDeposit;
        futureValueWithoutDeposit.push(endBalanceWithoutDeposit.toFixed(2));
      }
    }
  }
  //   console.log("Year Split: ", yearSplit);
  setYear(yearSplit);
  console.log("Future value with interest: ", futureValue);
  setfutureValue(futureValue);
  console.log("Value without deposit", futureValueWithoutDeposit);
  setFutureValueWithoutDeposit(futureValueWithoutDeposit);

  const res = await AdminApi.setCalculatorData({
    form_id: id,
    user_input: {
      number_of_periods: parseFloat(periods),
      starting_amount: parseFloat(amount),
      interest_rate: parseFloat(interestRate),
      periodic_deposit: parseFloat(deposit),
      PMT: radioVal,
    },
    yearly_graph: yearSplit,
    current_contributions: futureValue,
    no_additional_contributions: futureValueWithoutDeposit,
  });
  if (res?.status !== 200) {
    showTost({
      show: true,
      text: "Unable to save Future Value data. Please check your internet connection!",
      type: "error",
    });
  }
};
