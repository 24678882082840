import React, { memo, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styles from "./styles.module.scss";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, TextField } from "@mui/material";
import { setFormsData } from "../../store/slices/userSlice";

const dummyObj = { name: null, value: null };

const indexName = (childKey) => {
  let index;
  switch (childKey) {
    case 0:
      index = "st Allocation";
      break;
    case 1:
      index = "nd Allocation";
      break;
    case 2:
      index = "rd Allocation";
      break;
    default:
      index = "th Allocation";
  }
  return index;
};

export const SelectScreen = memo(
  ({
    childKey,
    currentQuestionIndex,
    newArr,
    dispatch,
    seterror,
    setdisabled,
    tickers,
  }) => {
    const [answers, setAnswers] = useState([]);
    const [percentage, setpercentage] = useState(0);
    const field = JSON.parse(
      JSON.stringify(newArr[currentQuestionIndex].data[childKey].answers)
    );

    useEffect(() => {
      return () => {
        seterror(null);
      };
    }, []);

    useEffect(() => {
      const answers = [];
      newArr[currentQuestionIndex].data[childKey].answers.filter((item) =>
        answers.push(item.name)
      );
      setAnswers(answers);

      let counter = 0;
      newArr[currentQuestionIndex].data[childKey].answers.filter((item) =>
        item.value !== "" && item.value !== null && item.value !== undefined
          ? (counter += parseFloat(item.value))
          : null
      );

      setpercentage(counter.toFixed(2));

      const checker = newArr[currentQuestionIndex].data[
        childKey
      ].answers.filter((item) => item.name === "" && item.value !== "");

      if (Math.round(counter * 100) / 100 !== 100) {
        setdisabled(true);
      } else if (checker.length > 0) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    }, [newArr, childKey, currentQuestionIndex]);

    const addMore = async () => {
      newArr[currentQuestionIndex].data[childKey].answers.push(dummyObj);
      dispatch(setFormsData(newArr));
    };

    const handleArr = (e, i, type) => {
      field.map((item, index) => ({
        ...item,
        name: type === "name" && i === index ? (item.name = e) : item.name,
        value:
          type === "value" && i === index
            ? (item.value = e)
            : type === "name" && i === index
            ? (item.value = null)
            : item.value,
      }));
      newArr[currentQuestionIndex].data[childKey].answers = field;
      dispatch(setFormsData(newArr));
    };

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 15,
      borderRadius: 40,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: percentage > 100 ? "#d4d5d6" : "#EDF3F3",
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 40,
        backgroundColor: percentage > 100 ? "#d4d5d6" : "#31B6C7",
      },
    }));

    return (
      <div>
        {childKey === 0 ? (
          <div className={styles.progressbarWidth}>
            <BorderLinearProgress
              variant="determinate"
              value={Number(percentage)}
            ></BorderLinearProgress>
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: 0,
                transform: "translateX(-50%)",
                color: "#000",
              }}
            >
              <div className={styles.percent}>{percentage}%</div>
            </div>
          </div>
        ) : null}

        {field.length > 0 ? (
          field.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                className={styles.inputWidth}
                key={index}
              >
                <Box sx={{ width: 335 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={tickers.map((option) => option)}
                      sx={{ width: 335 }}
                      id={`${item.name + item.value + index}`}
                      value={item?.name}
                      onChange={(_, newValue) => {
                        handleArr(newValue, index, "name");
                      }}
                      getOptionDisabled={(e) => answers.includes(e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={index + 1 + indexName(index)}
                          variant="outlined"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        value === null ||
                        value === undefined ||
                        value === "" ||
                        option === value
                      }
                    />
                  </FormControl>
                </Box>

                {item?.name !== null &&
                  item?.name !== "" &&
                  item?.name !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        type="number"
                        id="outlined-controlled"
                        label="Enter Percentage"
                        value={item.value ?? ""}
                        onChange={(e) =>
                          handleArr(e.target.value, index, "value")
                        }
                        style={{ marginLeft: 20, marginRight: 20 }}
                        className={styles.input}
                        InputProps={{ inputProps: { min: 0 } }}
                      />

                      {percentage < 100 &&
                        tickers.length > answers.length &&
                        item?.value !== null &&
                        item?.value !== "" &&
                        item?.value !== undefined &&
                        index + 1 === field.length && (
                          <div
                            className={styles.plus}
                            onClick={() => addMore()}
                          >
                            +
                          </div>
                        )}
                    </div>
                  )}
              </div>
            );
          })
        ) : (
          <div></div>
        )}

        {percentage > 100 && (
          <div className={styles.error}>Percentage cannot exceed 100%</div>
        )}
      </div>
    );
  }
);
