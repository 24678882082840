const toNumber = (formsData) => {
  const toNum = formsData
    .map((i) => ({
      ticker_name: i.name,
      percentage: i.value ? Number(i.value ?? 0) : "",
    }))
    .filter((j) => j.ticker_name !== "" && j.percentage !== "");
  return toNum;
};

export const submitFormsHandle = async (formsData, userData) => {
  let profiles = [];
  if (
    formsData[0].data[0].answer === "I’m American" &&
    formsData[0].data[1].answer !== "Child’s Education"
  ) {
    profiles = [
      {
        account_name: "ROTH_IRA",
        current_account_balance: Number(
          formsData[2].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[2].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[3].data[0].answers),
      },
      {
        account_name: "401K",
        current_account_balance: Number(
          formsData[4].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[4].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[5].data[0].answers),
      },
      {
        account_name: "UNREGISTERED_ACCOUNT",
        current_account_balance: Number(
          formsData[6].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[6].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[7].data[0].answers),
      },
    ];
  } else if (
    formsData?.[0]?.data?.[0]?.answer === "I’m American" &&
    formsData?.[0]?.data?.[1]?.answer === "Child’s Education"
  ) {
    //american child
    profiles = [
      {
        account_name: "529_PLAN",
        current_account_balance: Number(formsData[2].data[0].answer),
        annual_contribution: 0,
        account_allocations: toNumber(formsData[3].data[0].answers),
      },
    ];
  } else if (
    formsData?.[0]?.data?.[0]?.answer !== "I’m American" &&
    formsData?.[0]?.data?.[1]?.answer === "Child’s Education"
  ) {
    //canda or dual citizen child
    profiles = [
      {
        account_name: "RESP",
        current_account_balance: Number(
          formsData[2].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[2].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[3].data[0].answers),
      },
      {
        account_name: "UNREGISTERED_ACCOUNT",
        current_account_balance: Number(
          formsData[4].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[4].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[5].data[0].answers),
      },
    ];
  } else {
    profiles = [
      {
        account_name: "TFSA",
        current_account_balance: Number(
          formsData[2].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[2].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[3].data[0].answers),
      },
      {
        account_name: "RRSP",
        current_account_balance: Number(
          formsData[4].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[4].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[5].data[0].answers),
      },
      {
        account_name: "LIRA",
        current_account_balance: Number(
          formsData[6].data[0].additionalData[0].answer
        ),
        annual_contribution: 0,
        account_allocations: toNumber(formsData[7].data[0].answers),
      },
      {
        account_name: "UNREGISTERED_ACCOUNT",
        current_account_balance: Number(
          formsData[8].data[0].additionalData[0].answer
        ),
        annual_contribution: Number(
          formsData[8].data[0].additionalData[1].answer
        ),
        account_allocations: toNumber(formsData[9].data[0].answers),
      },
    ];
  }
  const residency =
    formsData[0].data[0].answer === "I’m Canadian"
      ? "CANADIAN"
      : formsData[0].data[0].answer === "I’m American"
      ? "AMERICAN"
      : "DUAL_CITIZEN";
  const portfolio =
    formsData[0].data[1].answer === "Retirement"
      ? "RETIREMENT"
      : formsData[0].data[1].answer === "Child’s Education"
      ? "CHILD_EDUCATION"
      : "GENERAL_INVESTMENT";
  const payload = {
    client_email: userData.payload.email,
    client_name: userData.payload.name,
    tax_residency: residency,
    portfolio_type: portfolio,
    target_balance: Number(formsData[1].data[1].answer) ?? null,
    target_year: Number(formsData[1].data[2].answer) ?? null,
    concerns: formsData[formsData.length - 2]?.data?.[0]?.answer ?? "",
    user_agreement:
      formsData[formsData.length - 1]?.data?.[0]?.answer === "I Accept"
        ? true
        : false,
    profiles: profiles.filter((a) => a?.account_allocations?.length > 0),
  };
  return payload;
};
