import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import PDFImg from "../../assets/pdf.png";
import { Stack } from "@mui/material";
import { EmailButton } from "../../assets/svg";
import { useSelector } from "react-redux";
import PDFGenerator from "./PDFGenerator";
import AdminApi from "../../api/AdminApi";
import Loader from "../../components/loading/Loader";
import ShowToast from "../../components/ShowToast";
import { useNavigate } from "react-router-dom";
import { generatePDF } from "../../utills/generatePDF";

const PDFScreen = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const isSubmitted = useSelector((state) => state.userData?.isSubmitted);
  const summary = useSelector((state) => state.userData?.summary);
  const geographicalPieData = useSelector(
    (state) => state.userData?.geographicalPieData
  );
  const sectorPieData = useSelector((state) => state.userData?.sectorPieData);
  const [loading, setloading] = useState(false);
  const [pdfloading, setpdfloading] = useState(false);
  const [futureGraphData, setFutureGraphData] = useState({});
  const [noteText, setnoteText] = useState("");
  const [toast, showTost] = useState({});
  const [loaded, setLoaded] = useState(false);

  const contentRef = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);
  const contentRef5 = useRef(null);
  const contentRef6 = useRef(null);
  const contentRef7 = useRef(null);
  const contentRef8 = useRef(null);
  const contentRef9 = useRef(null);
  const contentRef10 = useRef(null);
  const contentRef11 = useRef(null);
  const contentRef12 = useRef(null);
  const contentRef13 = useRef(null);
  const contentRef14 = useRef(null);

  useEffect(() => {
    if (
      isSubmitted &&
      !loaded &&
      noteText !== "" &&
      futureGraphData?.current_contributions?.length > 0
    ) {
      setLoaded(true);
      setPdf();
    }
  }, [isSubmitted, loaded, noteText, futureGraphData]);

  const setPdf = async () => {
    const payload = {
      userInfo: userInfo,
      summary: summary,
      geographicalPieData: geographicalPieData,
      sectorPieData: sectorPieData,
      futureGraphData: futureGraphData,
      noteText: noteText,
    };
    const res = await AdminApi.setPdfData({
      form_id: userInfo?._id,
      data: payload,
    });
    if (res?.status === 200 || res?.status === 201) {
      showTost({
        show: true,
        text: "Portfolio Submitted Successfully.",
        type: "success",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      const res = await AdminApi.getCalculatorData(userInfo?._id);
      if (res?.status === 200) {
        setFutureGraphData(res?.data?.future_calculator_data);
      }
      const response = await AdminApi.getNotes(userInfo?._id);
      if (response?.status === 200) {
        setnoteText(response?.data?.notes);
      }
      setloading(false);
    };
    fetchData();
  }, []);

  const handleDownload = async () => {
    setpdfloading(true);
    contentRef.current.style.display = "block";
    contentRef2.current.style.display = "block";
    contentRef3.current.style.display = "block";
    contentRef4.current.style.display = "block";
    contentRef5.current.style.display = "block";
    contentRef6.current.style.display = "block";
    contentRef7.current.style.display = "block";
    contentRef8.current.style.display = "block";
    contentRef8.current.style.display = "block";
    contentRef9.current.style.display = "block";
    contentRef10.current.style.display = "block";
    contentRef11.current.style.display = "block";
    contentRef12.current.style.display = "block";
    contentRef13.current.style.display = "block";
    contentRef14.current.style.display = "block";

    setTimeout(() => {
      generatePDF(
        userInfo?.client_name,
        contentRef,
        contentRef2,
        contentRef3,
        contentRef4,
        contentRef5,
        contentRef6,
        contentRef7,
        contentRef8,
        contentRef9,
        contentRef10,
        contentRef11,
        contentRef12,
        contentRef13,
        contentRef14,
        setloading,
        setpdfloading
      );
    }, 3000);
  };

  return (
    <div className={styles.container}>
      <ShowToast toast={toast} showTost={showTost} />
      <Header />
      {loading && <Loader />}
      {pdfloading && <div style={{ marginTop: 80 }} />}
      <div className={styles.subContainer}>
        <img src={PDFImg} className={styles.img} alt="n/a" />
        <div className={styles.right}>
          <p className={styles.title}>Hey {userInfo?.client_name},</p>
          <p
            className={styles.title}
            style={{ paddingTop: 5, paddingBottom: 20 }}
          >
            your portfolio is here!
          </p>

          <p className={styles.backToList} onClick={handleDownload}>
            {loading ? "Loading" : "Download PDF"}
          </p>

          <Stack
            direction="row"
            style={{ alignItems: "center", paddingBottom: 20 }}
          >
            <div
              onClick={() => {
                const subject = encodeURIComponent("");
                const sender = encodeURIComponent(userInfo?.client_email);
                const body = encodeURIComponent(`Hi!

Your portfolio assessment is complete and attached! This assessment provides a detailed breakdown of your asset allocation, geographical exposure, and market sector diversification. I have included some rough future value calculations to give you an idea of the potential of your portfolio as well. Finally, there are some comments at the end of the report directly from me.
This assessment was completed using the information you provided. However, stock prices fluctuate each day and your portfolio allocations are impacted by your contributions, withdrawals, dividends, and capital growth. It is recommended you get a portfolio assessment at least once per year to check up on your investments!
If you have any questions, please post them to the community group or submit them for our next live Q&A!

Thank you,`);
                const url = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&to=${sender}&body=${body}`;
                window.open(url, "_blank");
              }}
            >
              <Stack direction="row" className={styles.email}>
                <EmailButton />
                <p className={styles.btnText}>Email Investor</p>
              </Stack>
            </div>

            <div
              onClick={() => navigate("/admin/users")}
              className={styles.edit}
            >
              <p className={styles.btnText} style={{ color: "#000" }}>
                Back to List
              </p>
            </div>
          </Stack>
        </div>
      </div>

      <PDFGenerator
        pdfloading={pdfloading}
        userInfo={userInfo}
        futureGraphData={futureGraphData}
        noteText={noteText}
        summary={summary}
        geographicalPieData={geographicalPieData}
        sectorPieData={sectorPieData}
        contentRef={contentRef}
        contentRef2={contentRef2}
        contentRef3={contentRef3}
        contentRef4={contentRef4}
        contentRef5={contentRef5}
        contentRef6={contentRef6}
        contentRef7={contentRef7}
        contentRef8={contentRef8}
        contentRef9={contentRef9}
        contentRef10={contentRef10}
        contentRef11={contentRef11}
        contentRef12={contentRef12}
        contentRef13={contentRef13}
        contentRef14={contentRef14}
      />
    </div>
  );
};

export default PDFScreen;
