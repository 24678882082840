import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import Loader from "../../components/loading/Loader";
import StepperProgress from "../../components/Stepper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WhiteBgButton } from "../../components/buttons/WhiteBgButton";
import { BlueBgButton } from "../../components/buttons/BlueBgButton";
import AdminApi from "../../api/AdminApi";
import ShowToast from "../../components/ShowToast";
import { randomColors } from "../../utills";
import { setSectorPieData } from "../../store/slices/userSlice";
import PieChartScreen from "../../components/pieChart";
import ETFSTable from "../../components/ETFSTable";

const SectorBreakdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const targetData = useSelector(
    (state) => state.userData?.targetData?.common_sector_target
  );
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [toast, showTost] = useState({});
  const [pieData, setpieData] = useState([]);

  useEffect(() => {
    userInfo?._id && targetData?.Cash && fetchData();
  }, [userInfo?._id, targetData]);

  const fetchData = async () => {
    setloading(true);
    const res = await AdminApi.getSector(userInfo?._id);
    if (res?.status === 200) {
      const basic = parseFloat(targetData?.["Basic Materials"]);
      const cash = parseFloat(targetData?.Cash);
      const consumerDis = parseFloat(targetData?.["Consumer Discretionary"]);
      const consumerStaples = parseFloat(targetData?.["Consumer Staples"]);
      const energy = parseFloat(targetData?.Energy);
      const financials = parseFloat(targetData?.Financials);
      const healthCare = parseFloat(targetData?.["Health Care"]);
      const industrials = parseFloat(targetData?.Industrials);
      const realState = parseFloat(targetData?.["Real Estate"]);
      const technology = parseFloat(targetData?.Technology);
      const telecommunications = parseFloat(targetData?.Telecommunications);
      const utilities = parseFloat(targetData?.Utilities);

      const arrLength = res?.data?.sectors?.length;
      const adjustedObj = res?.data?.sectors[arrLength - 1];

      const targetDataObj = {
        Type: "-",
        Ticker: "Target",
        Value: "-",
        Financials: financials,
        Energy: energy,
        "Basic Materials": basic,
        Industrials: industrials,
        Technology: technology,
        "Consumer Discretionary": consumerDis,
        Telecommunications: telecommunications,
        Utilities: utilities,
        "Consumer Staples": consumerStaples,
        "Health Care": healthCare,
        "Real Estate": realState,
        Cash: cash,
      };

      const gapObj = {
        Type: "-",
        Ticker: "Gap",
        Value: "-",
        Financials: adjustedObj?.Financials - financials,
        Energy: adjustedObj?.Energy - energy,
        "Basic Materials": adjustedObj?.["Basic Materials"] - basic,
        Industrials: adjustedObj?.Industrials - industrials,
        Technology: adjustedObj?.Technology - technology,
        "Consumer Discretionary":
          adjustedObj?.["Consumer Discretionary"] - consumerDis,
        Telecommunications:
          adjustedObj?.Telecommunications - telecommunications,
        Utilities: adjustedObj?.Utilities - utilities,
        "Consumer Staples": adjustedObj?.["Consumer Staples"] - consumerStaples,
        "Health Care": adjustedObj?.["Health Care"] - healthCare,
        "Real Estate": adjustedObj?.["Real Estate"] - realState,
        Cash: adjustedObj?.Cash - cash,
      };

      setdata([...res?.data?.sectors, targetDataObj, gapObj]);
    }
    const response = await AdminApi.getSectorPie(userInfo?._id);
    if (response.status === 200) {
      const pieArr = [];
      Object.entries(response.data).map(([key, value], i) => {
        let obj;
        obj = {
          label: key,
          value: value,
          color: randomColors(key),
        };
        pieArr.push(obj);
      });
      setpieData(pieArr);
      dispatch(setSectorPieData(pieArr));
    }
    setloading(false);
  };

  return (
    <div className={styles.container}>
      <ShowToast toast={toast} showTost={showTost} />

      {loading && <Loader />}
      <Header />
      <p className={styles.name}>{userInfo?.client_name}</p>
      <StepperProgress step={3} />

      <div className={styles.subContainer}>
        {data?.length > 0 && (
          <ETFSTable data={data} red={true} type="Industry" />
        )}
        {pieData?.length > 0 && <PieChartScreen pieData={pieData} />}

        <div className={styles.buttonFlex}>
          <WhiteBgButton
            title={"Back to Geographical Exposure"}
            onClick={() =>
              navigate(`/admin/geographical-exposure?form_id=${userInfo?._id}`)
            }
            customStyle={{ paddingLeft: 20, paddingRight: 20 }}
          />
          <BlueBgButton
            title={"Go to Future Value Calculator"}
            onClick={() =>
              navigate(
                `/admin/future-value-calculator?form_id=${userInfo?._id}`
              )
            }
            customStyle={{ paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

export default SectorBreakdown;
