import { request } from "./config";

const post = async (url, body) => {
  const headers = {
    "x-api-key": "qVoQL9YuFG6NOAxpIQFIi8OtCvC0Wnqk7eudw81L",
  };
  const config = {
    baseURL: "https://6mym78utrb.execute-api.eu-west-1.amazonaws.com",
    url,
    method: "post",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const get = async (url, body) => {
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": "qVoQL9YuFG6NOAxpIQFIi8OtCvC0Wnqk7eudw81L",
  };
  const config = {
    baseURL: "https://6mym78utrb.execute-api.eu-west-1.amazonaws.com",
    url,
    method: "get",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const NetworkClient = {
  post,
  get,
};

export default NetworkClient;
