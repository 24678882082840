import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import { Stack } from "@mui/material";
import Loader from "../../components/loading/Loader";
import { WhiteBgButton } from "../../components/buttons";
import { Start } from "../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { SummaryDetails } from "./SummaryDetails";
import { setUserSummary } from "../../store/slices/userSlice";

const Summary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.userData?.userInfo?._id);
  const [data, setdata] = useState({});
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchData();
    } else {
      navigate("/admin/users");
    }
  }, [id]);

  const fetchData = async () => {
    setloading(true);
    const res = await AdminApi.getSummary(id);
    if (res?.status === 200) {
      setdata(res?.data);
      const payload = { ...res?.data };
      dispatch(setUserSummary(payload));
    }
    setloading(false);
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <Header />
      <div className={styles.subContainer}>
        <p className={styles.name}>{data?.client_name}</p>
        <p className={styles.des} style={{ textAlign: "center" }}>
          Below is a summary of the information that client provided for this
          portfolio assessment.
        </p>

        <SummaryDetails data={data} />

        <p className={styles.text} style={{ paddingTop: 10 }}>
          Concerns:
        </p>
        <p className={styles.des}>{data?.concerns}</p>
        {/* <p className={styles.text} style={{ paddingTop: 10 }}>
          Questions
        </p>
        <p className={styles.des}>
          Want to add REITs now that I have been doing this for almost a year,
          butwanted to see where I was at before adding. Will likely just start
          with 3-5%.
        </p> */}

        <Stack
          direction="row"
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <WhiteBgButton
            title="Back to List"
            onClick={() => navigate("/admin/users")}
          />
          <div onClick={() => navigate(`/admin/input?form_id=${id}`)}>
            <Stack direction="row" className={styles.start}>
              <p>Let’s Start</p>
              <Start />
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default Summary;
