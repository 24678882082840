import http from "./AdminHttpClient";

export default class AdminApi {
  static async adminLogin(payload) {
    const response = await http.post("/dev/admin/signin", payload);
    return response;
  }
  static async getUsers(payload) {
    const response = await http.get(`/dev/admin/user-list`, payload);
    return response;
  }
  static async refreshToken(payload) {
    const response = await http.post(`/dev/admin/refresh-token`, payload);
    return response;
  }
  static async getSummary(id) {
    const response = await http.get(
      `/dev/admin/portfolio/summary?form_id=${id}`
    );
    return response;
  }
  static async getInput(id) {
    const response = await http.get(
      `/dev/admin/portfolio/inputs?form_id=${id}`
    );
    return response;
  }
  static async getETFSIndustry(id) {
    const response = await http.get(
      `/dev/admin/portfolio/etfs/industry?form_id=${id}`
    );
    return response;
  }
  static async getETFSCountry(id) {
    const response = await http.get(
      `/dev/admin/portfolio/etfs/country?form_id=${id}`
    );
    return response;
  }
  static async getSector(id) {
    const response = await http.get(
      `/dev/admin/portfolio/etfs/sector?form_id=${id}`
    );
    return response;
  }
  static async getGeographicalExposure(id) {
    const response = await http.get(
      `/dev/admin/portfolio/etfs/geography?form_id=${id}`
    );
    return response;
  }
  static async setNotes(id, payload) {
    const response = await http.post(
      `/dev/admin/portfolio/notes?form_id=${id}`,
      payload
    );
    return response;
  }
  static async getNotes(id) {
    const response = await http.get(`/dev/admin/portfolio/notes?form_id=${id}`);
    return response;
  }
  static async getGeographyPie(id) {
    const response = await http.get(
      `/dev/admin/portfolio/piechart/geography?form_id=${id}`
    );
    return response;
  }
  static async getSectorPie(id) {
    const response = await http.get(
      `/dev/admin/portfolio/piechart/sector?form_id=${id}`
    );
    return response;
  }
  static async getCalculatorData(id) {
    const response = await http.get(
      `/dev/admin/portfolio/future-calculator?form_id=${id}`
    );
    return response;
  }
  static async setCalculatorData(payload) {
    const response = await http.post(
      `/dev/admin/portfolio/future-calculator`,
      payload
    );
    return response;
  }

  static async setComplete(payload) {
    const response = await http.post(`/dev/admin/portfolio/complete`, payload);
    return response;
  }

  static async getGeographyTargetData(id) {
    const response = await http.get(
      `/dev/admin/portfolio/store/geography/target?form_id=${id}`
    );
    return response;
  }
  static async setGeographyTargetData(payload) {
    const response = await http.post(
      `/dev/admin/portfolio/store/geography/target`,
      payload
    );
    return response;
  }
  static async getSectorTargetData(id) {
    const response = await http.get(
      `/dev/admin/portfolio/store/sector/target?form_id=${id}`
    );
    return response;
  }
  static async setSectorTargetData(payload) {
    const response = await http.post(
      `/dev/admin/portfolio/store/sector/target`,
      payload
    );
    return response;
  }
  static async setPdfData(payload) {
    const response = await http.post(`/dev/admin/portfolio/pdf`, payload);
    return response;
  }
  static async getPdfData(id) {
    const response = await http.get(`/dev/admin/portfolio/pdf?form_id=${id}`);
    return response;
  }
  static async getTargetSettings() {
    const response = await http.get(`/dev/admin/portfolio/store/common/target`);
    return response;
  }
  static async setTargetSettings(payload) {
    const response = await http.post(
      `/dev/admin/portfolio/store/common/target`,
      payload
    );
    return response;
  }
  static async getTickerList(payload) {
    const response = await http.get(`/dev/admin/tickers`, payload);
    return response;
  }
  static async getTickerListDetails(id) {
    const response = await http.get(`/dev/admin/tickers?name=${id}`);
    return response;
  }
  static async setTickerList(payload) {
    const response = await http.post(`/dev/admin/tickers`, payload);
    return response;
  }
  static async updateTickerList(id, payload) {
    const response = await http.put(`/dev/admin/tickers/${id}`, payload);
    return response;
  }
}
