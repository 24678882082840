const randomId = Math.random().toString(36).substring(3, 9);

export const mainData = [
  {
    id: 1000,
    data: [
      {
        id: randomId,
        type: "radio",
        title: "What is your tax residency?",
        description:
          "This will help us understand what accounts you’re investing in.",
        options: [
          {
            id: 0,
            name: "I’m Canadian",
          },
          {
            id: 1,
            name: "I’m American",
          },
          {
            id: 2,
            name: "I’m Dual Citizen",
          },
        ],
        answer: "",
        isRequired: true,
      },
      {
        id: randomId,
        type: "radio",
        title: "What portfolio are we analyzing today?",
        description: "Please select the purpose of your investment portfolio.",
        options: [
          {
            id: 0,
            name: "Retirement",
          },
          {
            id: 1,
            name: "Child’s Education",
          },
          {
            id: 2,
            name: "General Investment",
          },
        ],
        answer: "",
        isRequired: true,
      },
    ],
  },
];
