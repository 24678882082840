import { TextField } from "@mui/material";
import React from "react";

const TextFieldView = ({ label, value, setValue, type, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      fullWidth
      id="outlined-basic"
      label={label}
      variant="outlined"
      type={type}
      value={value}
      onChange={(event) => {
        setValue(event.target.value, label);
      }}
      onFocus={(e) =>
        e.target.addEventListener(
          "wheel",
          function (e) {
            e.preventDefault();
          },
          { passive: false }
        )
      }
    />
  );
};

export default TextFieldView;
