const storeData = async (key, value) => {
  try {
    await localStorage.setItem(key, value);
  } catch (e) {
    console.error("Data not saved!");
  }
};

const getData = async (key) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    return null;
  }
};

const clearData = async (key) => {
  try {
    await localStorage.removeItem(key);
  } catch (e) {
    console.error("Data not cleared!");
  }
};

const clearAll = async () => {
  try {
    localStorage.getAllKeys().then((keys) => localStorage.multiRemove(keys));
    await localStorage.clear();
  } catch (e) {
    console.warn("error while deleting storage", e);
  }
};

export { storeData, getData, clearAll, clearData };
