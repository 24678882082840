export const getGeographyArr = (data) => ({
  Canada: data?.country_weighting?.["Canada"] ?? 0,
  "Emerging Markets": data?.country_weighting?.["Emerging Markets"] ?? 0,
  Europe: data?.country_weighting?.["Europe"] ?? 0,
  "Middle East": data?.country_weighting?.["Middle East"] ?? 0,
  Other: data?.country_weighting?.["Other"] ?? 0,
  Pacific: data?.country_weighting?.["Pacific"] ?? 0,
  US: data?.country_weighting?.["US"] ?? 0,
});

export const getIndustryArr = (data) => ({
  "Basic Materials": data?.sector_weighting?.["Basic Materials"] ?? 0,
  Cash: data?.sector_weighting?.["Cash"] ?? 0,
  "Consumer Discretionary":
    data?.sector_weighting?.["Consumer Discretionary"] ?? 0,
  "Consumer Staples": data?.sector_weighting?.["Consumer Staples"] ?? 0,
  Energy: data?.sector_weighting?.["Energy"] ?? 0,
  Financials: data?.sector_weighting?.["Financials"] ?? 0,
  "Health Care": data?.sector_weighting?.["Health Care"] ?? 0,
  Industrials: data?.sector_weighting?.["Industrials"] ?? 0,
  "Real Estate": data?.sector_weighting?.["Real Estate"] ?? 0,
  Technology: data?.sector_weighting?.["Technology"] ?? 0,
  Telecommunications: data?.sector_weighting?.["Telecommunications"] ?? 0,
  Utilities: data?.sector_weighting?.["Utilities"] ?? 0,
});
