import React, { useEffect, useState } from "react";
import Loader from "../../components/loading/Loader";
import pdfImg from "../../assets/pdfBig.png";
import calculator from "../../assets/calculator.png";
// import styles from "./styles.module.scss";
import { SummaryDetails } from "../summary/SummaryDetails";
import { PieChart } from "@mui/x-charts";
import { Grid, Stack } from "@mui/material";
import PredictionGraph from "../../components/predictionGraph";
import { useNavigate } from "react-router-dom";

const styles = {
  pdfImg: {
    height: "100%",
    width: "100%",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    marginTop: 80,
  },
  flex2: {
    display: "flex",
  },
  innerFlex: {
    width: "33.33%",
    fontSize: 90,
    fontWeight: 400,
    color: "#02b5c4",
  },
  innerFlex2: {
    width: "33.33%",
    fontSize: 25,
    marginTop: 10,
    color: "#000",
  },
  textTitle: {
    color: "#00b5c4",
    fontSize: 24,
    fontWeight: 600,
    margin: 0,
  },
  des: {
    color: "#000",
    fontSize: 22,
    fontWeight: 400,
    textAlign: "left",
    lineHeight: 2,
  },
  leftFlex: {
    height: 230,
    width: "40%",
    backgroundColor: "#f1f1f1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  midFlex: {
    height: 230,
    width: "30%",
    backgroundColor: "#4b39b5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  rightFlex: {
    height: 230,
    width: "30%",
    backgroundColor: "#6bd1cb",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  flexTitle: {
    color: "#000",
    fontSize: 40,
    fontWeight: 700,
    margin: 0,
    paddingBottom: 20,
    textAlign: "center",
  },
  flexTitle1: {
    color: "#fff",
    fontSize: 40,
    fontWeight: 700,
    margin: 0,
    paddingBottom: 20,
    textAlign: "center",
  },
  flexDes: {
    color: "#000",
    fontSize: 27,
    margin: 0,
    textAlign: "center",
  },
  flexDes1: {
    color: "#fff",
    fontSize: 27,
    margin: 0,
    textAlign: "center",
  },
  calculator: {
    height: 180,
    width: "auto",
    alignSelf: "center",
  },
  calculatorContainer: {
    width: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 80,
  },
  graph: {
    paddingTop: 40,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
    border: "1px solid #e8e8e8",
    borderRadius: 4,
    marginBottom: 50,
  },
  round: {
    borderRadius: 3,
    background: "#0b3f45",
    width: 16,
    height: 16,
    marginRight: 10,
  },
};

const PDFGenerator = ({
  pdfloading,
  userInfo,
  futureGraphData,
  noteText,
  summary,
  geographicalPieData,
  sectorPieData,
  contentRef,
  contentRef2,
  contentRef3,
  contentRef4,
  contentRef5,
  contentRef6,
  contentRef7,
  contentRef8,
  contentRef9,
  contentRef10,
  contentRef11,
  contentRef12,
  contentRef13,
  contentRef14,
}) => {
  const navigate = useNavigate();
  const filterGeoPieData = geographicalPieData.filter((e) => e?.value > 0);
  const filterSectorPieData = sectorPieData.filter((e) => e?.value > 0);
  const [totalCurrent, setTotalCurrent] = useState(0);

  useEffect(() => {
    if (summary?.profiles?.length > 0) {
      let totalCurrent = 0;
      summary?.profiles.forEach((a) => {
        totalCurrent = totalCurrent + a?.current_account_balance;
      });
      setTotalCurrent(totalCurrent);
    }
  }, [summary?.profiles]);

  const showToastHandle = () => {
    alert("Please fill up and generate all steps!");
    navigate("/admin/users");
  };

  const noData = [
    {
      color: "#f2f2f2",
      label: "No Data",
      value: 100,
    },
  ];

  return (
    <div style={{ width: 1240 }}>
      {pdfloading && <Loader />}
      <div style={{ marginTop: pdfloading ? 200 : 0 }} />

      <div ref={contentRef} style={{ display: "none" }}>
        <img src={pdfImg} style={styles.pdfImg} alt="N/A" />
      </div>

      <div
        ref={contentRef2}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          paddingLeft: 100,
          paddingRight: 100,
          paddingTop: 100,
          display: "none",
        }}
      >
        <p
          style={{
            color: "#00B5C4",
            fontSize: 80,
            fontWeight: 700,
            textAlign: "left",
            margin: 0,
            marginBottom: 600,
          }}
        >
          Table of Contents
        </p>

        <div style={styles.flex}>
          <div style={styles.innerFlex}>01</div>
          <div style={styles.innerFlex}>02</div>
          <div style={styles.innerFlex}>04</div>
        </div>
        <div style={styles.flex2}>
          <div style={styles.innerFlex2}>Introduction</div>
          <div style={styles.innerFlex2}>Your Inputs</div>
          <div style={styles.innerFlex2}>Your Geographical Exposure</div>
        </div>

        <div style={styles.flex}>
          <div style={styles.innerFlex}>06</div>
          <div style={styles.innerFlex}>10</div>
          <div style={styles.innerFlex}>11</div>
        </div>
        <div style={styles.flex2}>
          <div style={styles.innerFlex2}>Your Sector Diversification</div>
          <div style={styles.innerFlex2}>Looking Forward</div>
          <div style={styles.innerFlex2}>Future Projections</div>
        </div>

        <div style={styles.flex}>
          <div style={styles.innerFlex}>12</div>
        </div>
        <div style={styles.flex2}>
          <div style={styles.innerFlex2}>Notes</div>
        </div>
      </div>

      <div
        ref={contentRef3}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          backgroundColor: "#02B5C4",
          display: "none",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            paddingTop: 60,
            paddingBottom: 70,
            paddingLeft: 50,
            paddingRight: 50,
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          <p
            style={{
              color: "#000",
              fontSize: 80,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            The Six-Figure Stock
          </p>
          <p
            style={{
              color: "#000",
              fontSize: 80,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            Portfolio
          </p>
          <p style={styles.des}>
            You’ve made the best investment in your financial security by taking
            your investment portfolio in your own hands. Self-directed investing
            allows you to minimize fees and maximize profit, while also
            tailoring your portfolio to match your risk tolerance and investment
            goals exactly. Now let’s confirm you’re on the right track.
          </p>

          <p style={styles.des}>
            The Six-Figure Stock Portfolio Analysis is a tool I built to review
            self-directed investment portfolios in order to ensure they are
            properly diversified to minimize risk while maintaining
            profitability.
          </p>

          <p style={styles.des}>
            This tool allows us to readily visualize asset allocations,
            geographical exposure, and market sector diversification to readily
            identify areas of improvement or confirm we are on the right track
            to meet our investment goals.
          </p>
        </div>

        <div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 30 }}>
          <p
            style={{
              color: "#fff",
              fontSize: 22,
              lineHeight: 2,
              textAlign: "left",
            }}
          >
            While this portfolio analysis will give you valuable insight into
            your investment portfolio to help you make better investment
            decisions going forward, this is not a financial plan.
          </p>

          <p
            style={{
              color: "#fff",
              fontSize: 22,
              lineHeight: 2,
              textAlign: "left",
            }}
          >
            Investing is a large aspect, but not the only aspect, of managing
            your finances. If you need help with budgeting, paying off debt, or
            making a large purchase, it is a great idea to sit down with a
            fee-only financial advisor and discuss your circumstances.
          </p>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 50,
            backgroundColor: "#fff",
            fontSize: 22,
            paddingTop: 11,
            paddingRight: 32,
            paddingBottom: 11,
            paddingLeft: 32,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 1
        </div>
      </div>

      <div
        ref={contentRef4}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            {userInfo?.client_name}
          </p>
          <p
            style={{
              color: "#000",
              fontSize: 22,
              lineHeight: 1.5,
              textAlign: "left",
              paddingBottom: 20,
            }}
          >
            Below is a summary of the information that client provided for this
            portfolio assessment.
          </p>

          <SummaryDetails data={summary} fullwidth={true} />

          {summary?.concerns && (
            <>
              <p
                style={{
                  color: "#000",
                  fontSize: 22,
                  lineHeight: 1.5,
                  textAlign: "left",
                  paddingTop: 20,
                  fontWeight: "bold",
                }}
              >
                Concerns:
              </p>
              <p style={styles.des}>{summary?.concerns}</p>
            </>
          )}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 2
        </div>
      </div>

      <div
        ref={contentRef5}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            Geographical Exposure
          </p>
          <p
            style={{
              color: "#000",
              fontSize: 22,
              lineHeight: 1.5,
              textAlign: "left",
              paddingBottom: 30,
            }}
          >
            Here is an overview of how your equity portfolio is allocated
            geographically.
          </p>

          <div style={{ marginLeft: 100, marginRight: 100 }}>
            <Grid
              container
              // spacing={{ xs: 3, md: 3 }}
              columnSpacing={12}
              columns={{ xs: 24, sm: 24, md: 24 }}
              style={{ alignItems: "center" }}
            >
              <Grid
                item
                xs={24}
                sm={24}
                md={24}
                style={{ justifyContent: "center", marginBottom: 50 }}
              >
                <PieChart
                  series={[
                    {
                      data:
                        filterGeoPieData?.length > 0
                          ? geographicalPieData
                          : noData,
                      valueFormatter: (v, { dataIndex }) => {
                        if (
                          geographicalPieData?.[dataIndex] === undefined ||
                          geographicalPieData?.[dataIndex] === null
                        ) {
                          showToastHandle();
                          return;
                        }
                        const { value } = geographicalPieData[dataIndex];
                        return `${filterGeoPieData?.length > 0 ? value : 0}%`;
                      },
                    },
                  ]}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                  height={400}
                  // width={400}
                />
              </Grid>
              {geographicalPieData.map((item, i) => (
                <Grid item xs={12} sm={12} md={12} key={i}>
                  <Stack
                    direction="row"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack direction="row" style={{ alignItems: "center" }}>
                      <div
                        style={{
                          borderRadius: 3,
                          backgroundColor: item.color ?? "#0b3f45",
                          width: 16,
                          height: 16,
                          marginRight: 10,
                        }}
                      />
                      <p style={{ fontSize: 18 }}>{item.label}</p>
                    </Stack>
                    <p style={{ fontSize: 18 }}>{item.value}%</p>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </div>

          <p
            style={{
              paddingTop: 20,
              color: "#000",
              fontSize: 22,
              fontWeight: 400,
              textAlign: "left",
              lineHeight: 2,
            }}
          >
            This pie chart was created from the data you submitted about your
            investment portfolio. It includes all different types of securities:
            stocks, bonds, ETFs, REITs, cryptocurrency and cash.
          </p>
          <p style={styles.des}>
            This graph represents the geographical location of all the
            securities in your portfolio. Some securities, like cryptocurrency,
            do not belong to a single region and therefore their region is
            designated as "other". Sometimes securities within an ETF are not
            assigned a region and the ETF provider also marks them as "other".
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 3
        </div>
      </div>

      <div
        ref={contentRef6}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
              paddingBottom: 40,
            }}
          >
            Geography
          </p>
          <Grid
            container
            // spacing={{ xs: 3, md: 3 }}
            columnSpacing={5}
            columns={{ xs: 24, sm: 24, md: 24 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <p style={styles.textTitle}>US</p>
              <p style={styles.des}>
                The US stock market is the largest and most profitable in the
                world, representing almost 60% of the global stock market. The
                two US stock market exchanges are the New York Stock Exchange
                and the NASDAQ.
              </p>
              <p style={styles.textTitle}>Emerging Markets</p>
              <p style={styles.des}>
                An emerging market economy is the economy of a developing
                nation. Emerging Markets includes regions like China, Taiwan,
                India, Mexico, Iceland, Saudi Arabia, Russia, and Brazil. Not
                all of these countries may be represented in an Emerging Markets
                allocation within an ETF.
              </p>
              <p style={styles.textTitle}>Middle East</p>
              <p style={styles.des}>
                The Middle East includes Israel. Markets like Saudi Arabia and
                Qatar are typically included under Emerging Markets.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p style={styles.textTitle}>Canada</p>
              <p style={styles.des}>
                Canada makes up less than 3% of the global stock market. The
                Canadian stock market exchange is called the Toronto Stock
                Exchange.
              </p>

              <p style={styles.textTitle}>Pacific</p>
              <p style={styles.des}>
                The Pacific stock market consists of markets located in the
                Pacific region. It includes Japan, Australia, South Korea, Hong
                Kong, and Singapore. Not all of these countries may be
                represented in a Pacific allocation within an ETF.
              </p>

              <p style={styles.textTitle}>Europe</p>
              <p style={styles.des}>
                Europe includes The United Kingdom, Germany, Switzerland,
                Norway, Spain, and France. However, not all of these countries
                may be represented in an Europe allocation within an ETF.
              </p>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 4
        </div>
      </div>

      <div
        ref={contentRef7}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            Sector Diversification
          </p>
          <p
            style={{
              color: "#000",
              fontSize: 22,
              lineHeight: 1.5,
              textAlign: "left",
              paddingBottom: 30,
            }}
          >
            Here is a breakdown of how the equity position of portfolio is
            allocated across market sectors.
          </p>

          <Grid
            container
            // spacing={{ xs: 3, md: 3 }}
            // columnSpacing={3}
            columns={{ xs: 24, sm: 24, md: 24 }}
            style={{ alignItems: "center" }}
          >
            <Grid
              item
              xs={24}
              sm={24}
              md={24}
              style={{ justifyContent: "center", marginBottom: 30 }}
            >
              <PieChart
                series={[
                  {
                    data:
                      filterSectorPieData?.length > 0 ? sectorPieData : noData,
                    valueFormatter: (v, { dataIndex }) => {
                      if (
                        sectorPieData?.[dataIndex] === undefined ||
                        sectorPieData?.[dataIndex] === null
                      ) {
                        showToastHandle();
                        return;
                      }
                      const { value } = sectorPieData[dataIndex];
                      return `${filterSectorPieData?.length > 0 ? value : 0}%`;
                    },
                  },
                ]}
                slotProps={{
                  legend: { hidden: true },
                }}
                height={400}
                // width={400}
              />
            </Grid>
            {sectorPieData.map((item, i) => (
              <Grid item xs={12} sm={8} md={8} key={i}>
                <Stack
                  direction="row"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 30,
                  }}
                >
                  <Stack direction="row" style={{ alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: item.color,
                        borderRadius: 3,
                        background: "#0b3f45",
                        width: 16,
                        height: 16,
                        marginRight: 10,
                      }}
                    />
                    <p style={{ fontSize: 18 }}>{item.label}</p>
                  </Stack>
                  <p style={{ fontSize: 18 }}>{item.value}%</p>
                </Stack>
              </Grid>
            ))}
          </Grid>

          <p
            style={{
              paddingTop: 20,
              color: "#000",
              fontSize: 22,
              fontWeight: 400,
              textAlign: "left",
              lineHeight: 2,
            }}
          >
            This pie chart was created from the data you submitted about your
            investment portfolio. It includes domestic and international ETFs,
            common stocks, and Real Estate ETFs. Bonds, Bond ETFs, or cash are
            not included in this analysis.
          </p>
          <p style={styles.des}>
            This chart is based on the The Global Industry Classification
            Standard (GICS). The GICS was jointly created by MSCI and S&P 500
            Dow Jones as an investment tool to classify industry sectors to
            understand global markets. A well-diversified investment portfolio
            has some exposure to all 11 industry sectors.
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 5
        </div>
      </div>

      <div
        ref={contentRef8}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
              paddingBottom: 40,
            }}
          >
            Sector Diversification
          </p>
          <p style={styles.des}>
            Understanding how your portfolio is diversified across different
            sectors of the economy is primarily for interest sake. You do not
            have to strive towards a perfect "balance" of exposure to each
            industry the same way you want a balance of stocks and bonds, or
            international and domestic securities. However, knowing what sectors
            you are over- or under-exposed to can suggest where to tweak your
            portfolio.
          </p>
          <p style={styles.des}>
            Generally, Financials and Technology are the largest sectors in the
            economy, and that should be reflected in your portfolio. If either
            or both of these lag behind other areas, you may want to consider
            some investments to make up for it.
          </p>
          <p style={styles.des}>
            As a general rule,{" "}
            <span style={{ fontWeight: "bold" }}>
              a single sector should not be greater than 25% of your entire
              portfolio.
            </span>{" "}
            If one or more sectors makes up more than 25% of your portfolio,
            consider diversifying into underrepresented areas.
          </p>
          <p style={styles.des}>
            The easiest ways to diversify your portfolio ranked from lowest to
            highest risk include:
          </p>
          <ul>
            <li
              style={{
                paddingTop: 20,
                color: "#000",
                fontSize: 22,
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 2,
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                Purchase more shares of your core broad market international and
                domestic ETFs.
              </span>{" "}
              These index ETFs are already well-diversified and will rapidly
              allocate your capital appropriately.
            </li>
            <li
              style={{
                paddingTop: 20,
                color: "#000",
                fontSize: 22,
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 2,
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                Purchase shares of niche ETFs in industries you want more
                exposure to.
              </span>{" "}
              Narrowly focused industry ETFs are a great way to get exposure to
              a specific sector of the market.
            </li>
            <li
              style={{
                paddingTop: 20,
                color: "#000",
                fontSize: 22,
                fontWeight: 400,
                textAlign: "left",
                lineHeight: 2,
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                Purchase shares of individual common stocks in industries you
                want more exposure to.
              </span>
              If you enjoy picking individual stocks, you can make your next
              investment into a company in an underrepresented sector of your
              portfolio.
            </li>
          </ul>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 6
        </div>
      </div>

      <div
        ref={contentRef9}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
              paddingBottom: 40,
            }}
          >
            Sectors
          </p>
          <Grid
            container
            // spacing={{ xs: 3, md: 3 }}
            columnSpacing={5}
            columns={{ xs: 24, sm: 24, md: 24 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <p style={styles.textTitle}>Financials</p>
              <p style={styles.des}>
                The financials sector consists of companies involved in banking,
                including mortgage and consumer finance, as well as investment
                banks, brokerage firms and insurance companies. The sector has
                shown robust growth and profitability, but can be affected
                significantly by the trend of interest rates, causing
                cyclicality.
              </p>
              <p style={styles.textTitle}>Industrials</p>
              <p style={styles.des}>
                The industrials sector includes companies that manufacture
                aerospace and defense products, electrical equipment and
                construction equipment. It also includes companies providing
                security services, employment services, professional services
                and transportation services. This sector may show strong growth
                during economic booms.
              </p>
              <p style={styles.textTitle}>Information Technology</p>
              <p style={styles.des}>
                The information technology sector includes companies that
                produce software and other IT products and services. It also
                contains companies that manufacture hardware such as
                communications equipment, mobile phones, computers and
                semiconductor equipment. This sector has been a fast grower and
                contains some of the market’s largest companies.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p style={styles.textTitle}>Energy</p>
              <p style={styles.des}>
                The energy sector includes companies engaged in exploration and
                production of oil and other hydrocarbons, refining, the
                transportation of oil and gas, and production of oil and gas
                equipment. The sector is generally mature with modest growth.
              </p>

              <p style={styles.textTitle}>Consumer Discretionary</p>
              <p style={styles.des}>
                The consumer discretionary sector includes companies that
                produce cars, durable goods, clothing and leisure equipment. It
                also includes restaurants, hotels and consumer retailing, among
                others. This sector is sensitive to economic cycles, so when the
                economy grows, these companies tend to grow much more quickly,
                though when it slows, this industry typically slows even more.
              </p>

              <p style={styles.textTitle}>Basic Materials</p>
              <p style={styles.des}>
                The materials sector includes companies that produce chemicals,
                glass, paper, forestry products, metals, packaging, construction
                materials and steel. It tends to be a mature industry with
                modest growth potential.
              </p>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 7
        </div>
      </div>

      <div
        ref={contentRef10}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
              paddingBottom: 40,
            }}
          >
            Sectors Cont'd
          </p>
          <Grid
            container
            // spacing={{ xs: 3, md: 3 }}
            columnSpacing={5}
            columns={{ xs: 24, sm: 24, md: 24 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <p style={styles.textTitle}>Telecommunications</p>
              <p style={styles.des}>
                The communication services sector includes telecommunication and
                media companies, entertainment companies and those producing
                content and interactive games. This sector can offer significant
                growth opportunities as the world moves more online, but older
                companies face significant challenges from more dynamic
                entrants.
              </p>
              <p style={styles.textTitle}>Consumer Staples</p>
              <p style={styles.des}>
                The consumer staples sector includes companies that produce
                food, drinks and tobacco, and non-durable household goods as
                well as those retailers that sell food and drugs, including
                retailing supercenters. This industry tends to be mature with
                modest growth.
              </p>
              <p style={styles.textTitle}>Real Estate</p>
              <p style={styles.des}>
                The real estate sector includes real estate services companies,
                real estate developers and equity REITs. This sector may offer
                strong growth opportunities, but shows steady growth overall.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p style={styles.textTitle}>Utilities</p>
              <p style={styles.des}>
                The utilities sector includes companies providing electricity,
                gas and water (from conventional and environmentally friendly
                sources) as well as energy traders and distributors of energy.
                This sector is generally a slow and steady performer, rather
                than a growth sector. But “green” energy offers the promise of
                higher returns, although with higher risk.
              </p>

              <p style={styles.textTitle}>Health Care</p>
              <p style={styles.des}>
                The health care sector includes companies that provide health
                care services, as well as health care equipment and technology.
                It includes companies at all stages of pharmaceutical and
                biotech research, development and production. This sector can be
                dynamic and exhibits above-trend growth, with some very quickly
                growing companies.
              </p>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 8
        </div>
      </div>

      <div
        ref={contentRef11}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
              paddingBottom: 40,
            }}
          >
            Looking Forward
          </p>
          <p style={styles.des}>
            No one can predict with certainty how the stock market will perform
            over coming decades. However, we can make guesses based on its
            historical performance.
          </p>
          <p style={styles.des}>
            The historical average annual rate of return for the US stock market
            is 10%. We will underestimate a slightly lower performance of 8% to
            account for holding a diversity of investments, the exposure to
            other markets that may underperform, and the limits of unlimited
            growth of the US market. We will also account for an average rate of
            inflation of 2%. Our estimated real return of our investment
            portfolio is 6%. However, the actual return may be lower or higher
            than this amount - only time will tell!
          </p>
          <p style={styles.des}>
            We can use a future value calculator to determine the approximate
            balance of your portfolio over time, whether you stop making
            contributions or continue at the pace you are.
          </p>
          <div style={styles.flex}>
            <div style={styles.leftFlex}>
              <p style={styles.flexTitle}>${totalCurrent.toFixed(2)}</p>
              <p style={styles.flexDes}>Current balance</p>
            </div>
            <div style={styles.midFlex}>
              <p style={styles.flexTitle1}>{summary?.target_year}</p>
              <p style={styles.flexDes1}>Years Until Withdrawal</p>
            </div>
            <div style={styles.rightFlex}>
              <p style={styles.flexTitle1}>6%</p>
              <p style={styles.flexDes1}>Estimated Average Annual Return</p>
            </div>
          </div>

          <p
            style={{
              paddingTop: 50,
              color: "#000",
              fontSize: 22,
              fontWeight: 400,
              textAlign: "left",
              lineHeight: 2,
            }}
          >
            If you would like to try your own future value calculations, check
            out the tool below:
          </p>

          <div style={styles.calculatorContainer}>
            <img src={calculator} style={styles.calculator} alt="N/A" />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 9
        </div>
      </div>

      <div
        ref={contentRef12}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            Future Projections
          </p>
          <p
            style={{
              color: "#000",
              fontSize: 22,
              lineHeight: 1.5,
              textAlign: "left",
              paddingBottom: 30,
            }}
          >
            Here is an estimate of how your portfolio may grow over time.
          </p>

          <div style={styles.graph}>
            <PredictionGraph
              futureValue={futureGraphData?.current_contributions ?? []}
              year={futureGraphData?.yearly_graph ?? []}
              futureValueWithoutDeposit={
                futureGraphData?.no_additional_contributions ?? []
              }
              height={600}
            />
          </div>

          <p style={styles.des}>
            You can see that you're already on track to build a great portfolio,
            even if you stop or reduce contributions to your account. But look
            how much more your portfolio will grow if you continue investing!
            You may even feel inspired to increase your contributions to reach
            your financial goals faster.
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 10
        </div>
      </div>

      <div
        ref={contentRef13}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
        }}
      >
        <div style={{ paddingRight: 80, paddingLeft: 60, paddingTop: 60 }}>
          <p
            style={{
              color: "#00B5C4",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            Notes
          </p>
          <p
            dangerouslySetInnerHTML={{ __html: noteText }}
            style={{
              color: "#000",
              fontSize: 22,
              lineHeight: 2,
              textAlign: "left",
              paddingBottom: 50,
            }}
          ></p>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: 50,
            fontSize: 22,
            color: "#000",
          }}
        >
          PORTFOLIO ANALYSIS / PAGE 11
        </div>
      </div>

      <div
        ref={contentRef14}
        style={{
          width: "100%",
          height: 1754,
          position: "relative",
          display: "none",
          backgroundColor: "#00B5C4",
        }}
      >
        <div
          style={{
            paddingRight: 80,
            paddingLeft: 60,
            paddingTop: 60,
          }}
        >
          <p
            style={{
              color: "#fff",
              fontSize: 60,
              fontWeight: 700,
              textAlign: "left",
              margin: 0,
            }}
          >
            Thank you.
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 150,
            left: 60,
            right: 80,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "40%" }}>
              <p
                style={{
                  color: "#FBCD07",
                  fontSize: 30,
                  paddingBottom: 50,
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Disclaimer
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: 22,
                  lineHeight: 1.5,
                  paddingBottom: 50,
                  textAlign: "left",
                  margin: 0,
                  fontWeight: 400,
                }}
              >
                This document was assembled using data submitted by the user and
                market data provided by publicly traded securities. Bridget
                Casey or Money After Graduation Inc. or any employee thereof is
                not responsible for any errors that arise due to the submission
                of incorrect information. This is for informational purposes
                only and does not constitute individual financial advice.
              </p>
            </div>
            <div style={{ alignSelf: "flex-end" }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: 20,
                  lineHeight: 1.5,
                  paddingBottom: 50,
                  whiteSpace: "pre-wrap",
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Money After Graduation Inc. {"\n"}10160 103 St NW {"\n"}
                Edmonton, AB
                {"\n"}support@bridgetcasey.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFGenerator;
