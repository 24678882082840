import React, { memo, useState } from "react";
import styles from "./styles.module.scss";
import { Grid, TextField } from "@mui/material";
import { WhiteBgButton } from "../../components/buttons/WhiteBgButton";
import { BlueBgButton } from "../../components/buttons/BlueBgButton";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";

const Geography = memo(
  ({ data, othersData, setLoading, fetchData, showTost }) => {
    const navigate = useNavigate();
    const [us, setUs] = useState(data?.US ?? "");
    const [canada, setcanada] = useState(data?.Canada ?? "");
    const [europe, seteurope] = useState(data?.Europe ?? "");
    const [pecific, setpecific] = useState(data?.Pacific ?? "");
    const [emergingMarkets, setemergingMarkets] = useState(
      data?.Emerging_Markets ?? ""
    );
    const [middle, setmiddle] = useState(data?.Middle_East ?? "");
    const [others, setothers] = useState(data?.Other ?? "");
    const [disabled, setdisabled] = useState(true);

    const handleClick = async () => {
      if (disabled) {
        setdisabled(false);
      } else {
        if (
          us === "" ||
          canada === "" ||
          europe === "" ||
          pecific === "" ||
          emergingMarkets === "" ||
          middle === "" ||
          others === ""
        ) {
          showTost({
            show: true,
            text: "The input field can't be empty! Please enter a value or set it to 0.",
            type: "error",
          });
          return;
        }
        setLoading(true);
        const payload = {
          common_geography_target: {
            US: us,
            Canada: canada,
            Europe: europe,
            Pacific: pecific,
            Emerging_Markets: emergingMarkets,
            Middle_East: middle,
            Other: others,
          },
          common_sector_target: othersData,
        };
        const res = await AdminApi.setTargetSettings(payload);

        if (res?.status === 200 || res?.status === 201) {
          showTost({
            show: true,
            text: "Target value has been updated.",
            type: "success",
          });
          fetchData();
        } else {
          showTost({
            show: true,
            text: "Error! Please try again or later.",
            type: "error",
          });
          setLoading(false);
        }
        setdisabled(true);
      }
    };

    return (
      <div>
        <Grid
          container
          columnSpacing={{ xs: 8, md: 12 }}
          rowSpacing={{ xs: 3, md: 5 }}
          columns={{ xs: 12, sm: 24, md: 24 }}
          style={{ alignItems: "center" }}
        >
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="US"
              className={styles.textField}
              value={us}
              onChange={(e) => setUs(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Canada"
              className={styles.textField}
              value={canada}
              onChange={(e) => setcanada(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Europe"
              className={styles.textField}
              value={europe}
              onChange={(e) => seteurope(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Pacific"
              className={styles.textField}
              value={pecific}
              onChange={(e) => setpecific(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Emerging Markets"
              className={styles.textField}
              value={emergingMarkets}
              onChange={(e) => setemergingMarkets(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Middle East"
              className={styles.textField}
              value={middle}
              onChange={(e) => setmiddle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Other"
              className={styles.textField}
              value={others}
              onChange={(e) => setothers(e.target.value)}
            />
          </Grid>
        </Grid>
        <div className={styles.buttonFlex}>
          <BlueBgButton
            title={disabled ? "Edit" : "Save"}
            onClick={handleClick}
            customStyle={{
              paddingLeft: 40,
              paddingRight: 40,
              marginRight: 20,
            }}
          />
          <WhiteBgButton
            title={"Back to List"}
            onClick={() => navigate(`/admin/users`)}
          />
        </div>
      </div>
    );
  }
);

export default Geography;
