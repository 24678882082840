export const americanArr = [
  {
    id: 1001,
    data: [
      {
        id: 5001,
        type: "text",
        header: "Retirement Portfolio Analysis",
        description:
          "Now we'll ask some questions about your accounts, their balances, and asset allocations.",
        isRequired: false,
      },
      {
        id: 5002,
        type: "input",
        inputType: "number",
        placeholder: "Enter dollar amount",
        title:
          "Do you have a goal or target balance you want this retirement portfolio to grow to?",
        description:
          "Enter the dollar amount you are hoping this portfolio grows to over time. If you do not have a target balance, you may leave this blank.",
        answer: "",
        isRequired: true,
      },
      {
        id: 5003,
        type: "input",
        inputType: "number",
        placeholder: "Enter remaining years",
        title:
          "How many years until you expect to withdraw from this portfolio?",
        description:
          "Enter the number of years until you plan to make your first withdrawal. For example, if you are 32 years old and will retire at 65, then you would write 33 below.",
        answer: "",
        isRequired: true,
      },
    ],
  },
  //IRA
  {
    id: 1002,
    data: [
      {
        id: 5004,
        type: "radio",
        title: "Is a Roth IRA part of this portfolio?",
        description:
          "A Roth IRA is an Individual Retirement Account to which you contribute after-tax dollars",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 5005,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your Roth IRA?",
            answer: "",
            isRequired: true,
          },
          {
            id: 5006,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "How much do you contribute to your Roth IRA per year?",
            description: "Enter the dollar amount. An approximation is ok!",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1003,
    data: [
      {
        id: 5007,
        type: "select",
        title: "Please list your Roth IRA asset allocation in detail. *",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },

  //401K
  {
    id: 1004,
    data: [
      {
        id: 5008,
        type: "radio",
        title: "Is a 401K part of this portfolio?",
        description:
          "A 401(k) is a feature of a qualified profit-sharing plan that allows employees to contribute a portion of their wages to individual accounts",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 5009,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your 401K?",
            answer: "",
            isRequired: true,
          },
          {
            id: 5010,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "How much do you contribute to your 401K per year?",
            description: "Enter the dollar amount. An approximation is ok!",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1005,
    data: [
      {
        id: 5011,
        type: "select",
        title: "Please list your 401K allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },
  //unregistered account
  {
    id: 1006,
    data: [
      {
        id: 5012,
        type: "radio",
        title: "Is an unregistered account part of this portfolio?",
        description:
          "An unregistered account is any account without a tax-designation. Basically any investment account that is not a TFSA, RRSP, RRIF, etc. Employer pensions are not unregistered accounts.",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 5013,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your unregistered account?",
            answer: "",
            isRequired: true,
          },
          {
            id: 5014,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title:
              "How much do you contribute to your unregistered account per year?",
            description: "Enter the dollar amount. An approximation is ok!",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1007,
    data: [
      {
        id: 5015,
        type: "select",
        title: "Please list your unregistered account allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },

  //last phase
  {
    id: 1008,
    data: [
      {
        id: 5016,
        type: "textArea",
        title:
          "Do you have any concerns or worries about your existing portfolio allocation?",
        placeholder: "Type here",
        isRequired: true,
        answers: "",
        submitButton: "Go To User Aggreement",
      },
    ],
  },
  {
    id: 1009,
    data: [
      {
        id: 5017,
        type: "radio",
        header: "User Agreement.*",
        description:
          "By checking “I accept” below, you understand that this Portfolio Assessment has been completed using the information you submitted. We are not responsible for any errors due to omission or incorrect information. Any conclusions and projections made with the information you provide are based on historical stock market performance and current security prices, they do not predict or guarantee future investment performance. The Six-Figure Portfolio Assessment tool evaluates your existing portfolio for informational purposes only and does not provide any security recommendations, buy or sell suggestions, or any other trading recommendations. You are responsible for researching and selecting securities, and managing your investment portfolio allocations. While we hope that this tool is valuable in the context of your financial planning, it is not a substitute for professional financial advice via a certified fee-only financial advisor.",
        isRequired: true,
        answer: "",
        submitButton: "Submit Your Profile",
        options: [
          {
            id: 0,
            name: "I Accept",
          },
          {
            id: 1,
            name: "I Don’t Accept",
          },
        ],
      },
    ],
  },
];
