import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Admin from "./pages/admin/Admin";
import Investor from "./pages/investor/Investor";
import NotFound from "./pages/notFound";
import InputForms from "./pages/forms";
import SubmittedScreen from "./pages/submitted";
import { useSelector } from "react-redux";
import Users from "./pages/users/Users";
import Summary from "./pages/summary";
import InputScreen from "./pages/input";
import PDFScreen from "./pages/pdfScreen";
import BridgetsNotes from "./pages/bridgetsNote/BridgetsNotes";
import FutureValueCalculator from "./pages/futureValueCalculator";
import ETFS from "./pages/etfs";
import GeographicalExposure from "./pages/geographicalExposure";
import SectorBreakdown from "./pages/sectorBreakdown";
import { useEffect } from "react";
import TargetSettings from "./pages/targetSettings";
import TickerList from "./pages/tickerList";
import TickerListDetails from "./pages/tickerListDetails";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const loginStatus = useSelector((state) => state?.userData?.loginStatus);

  // useEffect(() => {
  //   if (!navigator.onLine || navigator.onLine === "offline") {
  //     navigate("*");
  //   }
  // }, []);

  useEffect(() => {
    if (
      (location.pathname === "/admin" || location.pathname === "/admin/") &&
      loginStatus
    ) {
      navigate("/admin/users");
    }
  }, [location?.pathname, loginStatus]);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Investor />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/:id" element={<InputForms />} />
      <Route path="/submitted" element={<SubmittedScreen />} />

      {loginStatus && (
        <>
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/summary" element={<Summary />} />
          <Route path="/admin/input" element={<InputScreen />} />
          <Route path="/admin/pdf" element={<PDFScreen />} />
          <Route path="/admin/bridgets-note" element={<BridgetsNotes />} />
          <Route
            path="/admin/future-value-calculator"
            element={<FutureValueCalculator />}
          />
          <Route path="/admin/etfs" element={<ETFS />} />
          <Route
            path="/admin/geographical-exposure"
            element={<GeographicalExposure />}
          />
          <Route path="/admin/sector-breakdown" element={<SectorBreakdown />} />
          <Route path="/admin/target-settings" element={<TargetSettings />} />
          <Route path="/admin/ticker-list" element={<TickerList />} />
          <Route
            path="/admin/ticker-list/:id"
            element={<TickerListDetails />}
          />
        </>
      )}
    </Routes>
  );
}

export default App;
