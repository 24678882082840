import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StepperProgress from "../../components/Stepper";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { BlueBgButton } from "../../components/buttons/BlueBgButton";
import { WhiteBgButton } from "../../components/buttons/WhiteBgButton";
import PredictionGraph from "../../components/predictionGraph";
import ShowToast from "../../components/ShowToast";
import { CalculatorAndGraph } from "../../utills";
import AdminApi from "../../api/AdminApi";
import Loader from "../../components/loading/Loader";
import { setCalculatorVisited } from "../../store/slices/userSlice";

const FutureValueCalculator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const userSummary = useSelector((state) => state.userData?.summary);
  const [loading, setloading] = useState(false);
  const [periods, setperiods] = useState("");
  const [amount, setamount] = useState("");
  const [interestRate, setinterestRate] = useState("6");
  const [deposit, setdeposit] = useState("");
  const [radioVal, setradioVal] = useState("");
  const [toast, showTost] = useState({});
  const [futureValue, setfutureValue] = useState([]);
  const [year, setYear] = useState([]);
  const [futureValueWithoutDeposit, setFutureValueWithoutDeposit] = useState(
    []
  );

  useEffect(() => {
    if (userSummary?.profiles?.length > 0) {
      let totalCurrent = 0;
      let totalDesposit = 0;
      userSummary?.profiles.forEach((a) => {
        totalCurrent = totalCurrent + a?.current_account_balance;
        totalDesposit = totalDesposit + a?.annual_contribution;
      });
      setperiods(userSummary?.target_year);
      setamount(totalCurrent);
      setdeposit(totalDesposit);
    }
  }, [userSummary?.profiles]);

  useEffect(() => {
    fetchData();
  }, [userInfo._id]);

  const fetchData = async () => {
    setloading(true);
    const res = await AdminApi.getCalculatorData(userInfo?._id);
    // console.log("==================", res?.data?.future_calculator_data);
    if (res?.status === 200 && res?.data?.future_calculator_data?.form_id) {
      setperiods(
        res?.data?.future_calculator_data?.user_input?.number_of_periods ?? ""
      );
      setamount(
        res?.data?.future_calculator_data?.user_input?.starting_amount ?? ""
      );
      setinterestRate(
        res?.data?.future_calculator_data?.user_input?.interest_rate ?? ""
      );
      setdeposit(
        res?.data?.future_calculator_data?.user_input?.periodic_deposit ?? ""
      );
      setradioVal(res?.data?.future_calculator_data?.user_input?.PMT ?? "");
      setfutureValue(
        res?.data?.future_calculator_data?.current_contributions ?? []
      );
      setYear(res?.data?.future_calculator_data?.yearly_graph ?? []);
      setFutureValueWithoutDeposit(
        res?.data?.future_calculator_data?.no_additional_contributions ?? []
      );
      dispatch(setCalculatorVisited());
    }
    setloading(false);
  };

  const calculateHandle = () => {
    if (periods === "") {
      showTost({
        show: true,
        text: "Please enter the number of periods",
        type: "error",
      });
    } else if (amount === "") {
      showTost({
        show: true,
        text: "Please enter the starting amount",
        type: "error",
      });
    } else if (interestRate === "") {
      showTost({
        show: true,
        text: "Please enter the interest rate",
        type: "error",
      });
    } else if (deposit === "") {
      showTost({
        show: true,
        text: "Please enter the periodic deposit amount",
        type: "error",
      });
    } else if (radioVal === "") {
      showTost({
        show: true,
        text: "Please select the compound period",
        type: "error",
      });
    } else {
      CalculatorAndGraph(
        periods,
        amount,
        interestRate,
        deposit,
        radioVal,
        setfutureValue,
        setYear,
        setFutureValueWithoutDeposit,
        userInfo?._id,
        showTost
      );
      dispatch(setCalculatorVisited());
    }
  };

  const clearHandle = () => {
    setperiods("");
    setamount("");
    setinterestRate("");
    setdeposit("");
    setradioVal("");
  };

  const setValue = (e, set) => {
    const toNum = Number(Array.from(e)[0]);
    if (Array.from(e)[0] === "0" || Array.from(e)[0] === "-" || toNum <= 0) {
      e = null;
      showTost({
        show: true,
        text: "First digit cannot be less than 0",
        type: "error",
      });
    } else {
      set(e);
    }
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <ShowToast toast={toast} showTost={showTost} />
      <Header />
      <p className={styles.name}>{userInfo?.client_name}</p>
      <StepperProgress step={4} />

      <div className={styles.subContainer}>
        <p className={styles.title}>Future Value Calculator</p>
        <p className={styles.des1}>
          The future value calculator can be used to calculate the future value
          (FV) of an investment with given inputs of compounding periods (N),
          interest/yield rate (I/Y), starting amount, and periodic
          deposit/annuity payment per period (PMT)
        </p>

        <div className={styles.flexContainer}>
          <div className={styles.left}>
            <TextField
              // disabled
              required
              id="outlined-controlled"
              label="Number of Periods(N)"
              className={styles.input}
              type="number"
              value={periods}
              onChange={(e) => setValue(e.target.value, setperiods)}
              style={{ paddingBottom: 15 }}
            />

            <TextField
              // disabled
              required
              id="outlined-controlled"
              label="Starting Amount(PV)"
              className={styles.input}
              type="number"
              value={amount}
              onChange={(e) => setValue(e.target.value, setamount)}
              style={{ paddingBottom: 15 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p style={{ color: "#000" }}>$</p>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              // disabled
              required
              id="outlined-controlled"
              label="Interest Rate(I/Y)"
              className={styles.input}
              type="number"
              value={interestRate}
              onChange={(e) => setValue(e.target.value, setinterestRate)}
              style={{ paddingBottom: 15 }}
            />

            <TextField
              required
              id="outlined-controlled"
              label="Periodic Deposit(PMT)/Period"
              className={styles.input}
              type="number"
              value={deposit}
              onChange={(e) => setdeposit(e.target.value)}
              style={{ paddingBottom: 15 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p style={{ color: "#000" }}>$</p>
                  </InputAdornment>
                ),
              }}
            />

            <p className={styles.des}>PMT made at the </p>
            <FormControl style={{ marginTop: 5, marginBottom: 5 }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={radioVal}
                onChange={(e) => setradioVal(e.target.value)}
              >
                <FormControlLabel
                  value="BEGINNING"
                  control={<Radio />}
                  label="Beginning"
                  style={{ paddingRight: 20 }}
                />
                <FormControlLabel value="END" control={<Radio />} label="End" />
              </RadioGroup>
            </FormControl>
            <p className={styles.des}>of each compound period</p>

            <Stack
              direction="row"
              spacing={2}
              style={{ alignItems: "center", marginTop: 20 }}
            >
              <BlueBgButton title={"Calculate"} onClick={calculateHandle} />
              <WhiteBgButton title={"Clear"} onClick={clearHandle} />
            </Stack>
          </div>
          {futureValue.length === year.length &&
            year.length === futureValueWithoutDeposit.length && (
              <div className={styles.right}>
                <PredictionGraph
                  futureValue={futureValue}
                  year={year}
                  futureValueWithoutDeposit={futureValueWithoutDeposit}
                />
              </div>
            )}
        </div>

        <div className={styles.buttonFlex}>
          <WhiteBgButton
            title={"Back to Sector Breakdown"}
            onClick={() =>
              navigate(`/admin/sector-breakdown?form_id=${userInfo?._id}`)
            }
            customStyle={{ paddingLeft: 20, paddingRight: 20 }}
          />
          <BlueBgButton
            title={"Go to Notes"}
            onClick={() => {
              if (futureValue?.length > 0) {
                navigate(`/admin/bridgets-note?form_id=${userInfo?._id}`);
              } else {
                showTost({
                  show: true,
                  text: "Please generate future value calculator data!",
                  type: "error",
                });
              }
            }}
            customStyle={{ paddingLeft: 20, paddingRight: 20, marginLeft: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

export default FutureValueCalculator;
