import { TextField } from "@mui/material";
import React from "react";
import { CustomButton } from "../buttons";
import styles from "./styles.module.scss";

const LoginScreen = ({
  title,
  name,
  setname,
  setErrorMsg,
  errorMsg,
  email,
  setemail,
  setErrorMsg1,
  errorMsg1,
  password,
  setPasword,
  setErrorMsg2,
  errorMsg2,
  handleSubmit,
  fromAdmin = false,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <img
          alt="n/a"
          src={require("../../assets/bigLogo.png")}
          className={styles.logo}
        />
        <p className={styles.title}>{title}</p>
        <form>
          {!fromAdmin && (
            <div>
              <TextField
                required
                id="outlined-controlled"
                label="What’s Your name"
                className={styles.name}
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                  setErrorMsg(null);
                }}
              />
              {errorMsg && <p className={styles.err}>{errorMsg}</p>}
            </div>
          )}
          <TextField
            required
            type="email"
            id="outlined-controlled2"
            label={`${fromAdmin ? "Enter" : "Please enter"} your email address`}
            className={styles.name}
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
              setErrorMsg1(null);
            }}
          />
          {errorMsg1 && <p className={styles.err}>{errorMsg1}</p>}

          {fromAdmin && (
            <div>
              <TextField
                required
                type="password"
                id="outlined-controlled3"
                label="Enter your password"
                className={styles.name}
                value={password}
                onChange={(e) => {
                  setPasword(e.target.value);
                  setErrorMsg2(null);
                }}
              />
              {errorMsg2 && <p className={styles.err}>{errorMsg2}</p>}
            </div>
          )}

          {!fromAdmin && (
            <p className={styles.des}>
              This email address should match your course login email address.
              The results of the Portfolio Assessment will be sent here.
            </p>
          )}

          <CustomButton
            title="Let’s Start"
            onClick={() => handleSubmit()}
            customStyle={{
              paddingLeft: 130,
              paddingRight: 130,
              paddingTop: 15,
              paddingBottom: 15,
              marginTop: 30,
            }}
          />
        </form>
      </div>

      <div className={styles.right}>
        <img
          alt="n/a"
          src={require("../../assets/emailInputLogo.png")}
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default LoginScreen;
