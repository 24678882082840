export const childIsAmericanArr = [
  {
    id: 1001,
    data: [
      {
        id: 1111,
        type: "text",
        header: "Children’s Education Portfolio Analysis",
        description:
          "Now we'll ask some questions about your accounts, their balances, and asset allocations.",
        isRequired: false,
      },
      {
        id: 1112,
        type: "input",
        inputType: "number",
        placeholder: "Enter dollar amount",
        title:
          "Do you have a goal or target balance you want this child's education portfolio to grow to?",
        description:
          "Enter the dollar amount you are hoping this portfolio grows to over time. If you do not have a target balance, you may leave this blank.",
        answer: "",
        isRequired: true,
      },
      {
        id: 1113,
        type: "input",
        inputType: "number",
        placeholder: "Enter remaining years",
        title:
          "Enter the number of years until you plan to make your first withdrawal. For example, if your child is 6 and you expect them to begin post-secondary at age 18, put 12.",
        description:
          "Enter the number of years until you plan to make your first withdrawal. For example, if you are 32 years old and will retire at 65, then you would write 33 below.",
        answer: "",
        isRequired: true,
      },
    ],
  },

  //529
  {
    id: 1002,
    data: [
      {
        id: 5014,
        type: "input",
        inputType: "number",
        placeholder: "Type your answer here",
        title: "How much do you contribute to the 529 Plan per year?",
        description:
          "A 529 plan is a tax-advantaged savings plan designed to help pay for education",
        answer: "",
        isRequired: true,
      },
    ],
  },
  {
    id: 1003,
    data: [
      {
        id: 5015,
        type: "select",
        title: "Please list the 529 Plan allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100%",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },

  //last phase
  {
    id: 1004,
    data: [
      {
        id: 5016,
        type: "textArea",
        title:
          "Do you have any concerns or worries about your existing portfolio allocation?",
        placeholder: "Type here",
        isRequired: true,
        answers: "",
        submitButton: "Go To User Aggreement",
      },
    ],
  },
  {
    id: 1005,
    data: [
      {
        id: 5017,
        type: "radio",
        header: "User Agreement.*",
        description:
          "By checking “I accept” below, you understand that this Portfolio Assessment has been completed using the information you submitted. We are not responsible for any errors due to omission or incorrect information. Any conclusions and projections made with the information you provide are based on historical stock market performance and current security prices, they do not predict or guarantee future investment performance. The Six-Figure Portfolio Assessment tool evaluates your existing portfolio for informational purposes only and does not provide any security recommendations, buy or sell suggestions, or any other trading recommendations. You are responsible for researching and selecting securities, and managing your investment portfolio allocations. While we hope that this tool is valuable in the context of your financial planning, it is not a substitute for professional financial advice via a certified fee-only financial advisor.",
        isRequired: true,
        answer: "",
        submitButton: "Submit Your Profile",
        options: [
          {
            id: 0,
            name: "I Accept",
          },
          {
            id: 1,
            name: "I Don’t Accept",
          },
        ],
      },
    ],
  },
];
