import React, { memo, useState } from "react";
import styles from "./styles.module.scss";
import { Grid, TextField } from "@mui/material";
import { WhiteBgButton } from "../../components/buttons/WhiteBgButton";
import { BlueBgButton } from "../../components/buttons/BlueBgButton";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";

const Industry = memo(
  ({ data, othersData, setLoading, fetchData, showTost }) => {
    const navigate = useNavigate();
    const [disabled, setdisabled] = useState(true);
    const [financials, setfinancials] = useState(data?.["Financials"] ?? "");
    const [energy, setenergy] = useState(data?.["Energy"] ?? "");
    const [basicMaterials, setbasicMaterials] = useState(
      data?.["Basic Materials"] ?? ""
    );
    const [industrials, setindustrials] = useState(data?.["Industrials"] ?? "");
    const [technology, settechnology] = useState(data?.["Technology"] ?? "");
    const [consumer, setconsumer] = useState(
      data?.["Consumer Discretionary"] ?? ""
    );
    const [telecommunications, settelecommunications] = useState(
      data?.["Telecommunications"] ?? ""
    );
    const [utilities, setutilities] = useState(data?.["Utilities"] ?? "");
    const [staples, setstaples] = useState(data?.["Consumer Staples"] ?? "");
    const [healthCare, sethealthCare] = useState(data?.["Health Care"] ?? "");
    const [realState, setrealState] = useState(data?.["Real Estate"] ?? "");
    const [cash, setcash] = useState(data?.["Cash"] ?? "");

    const handleClick = async () => {
      if (disabled) {
        setdisabled(false);
      } else {
        if (
          financials === "" ||
          energy === "" ||
          basicMaterials === "" ||
          industrials === "" ||
          technology === "" ||
          consumer === "" ||
          telecommunications === "" ||
          utilities === "" ||
          staples === "" ||
          healthCare === "" ||
          realState === "" ||
          cash === ""
        ) {
          showTost({
            show: true,
            text: "The input field can't be empty! Please enter a value or set it to 0.",
            type: "error",
          });
          return;
        }
        setLoading(true);
        const payload = {
          common_sector_target: {
            Financials: financials,
            Energy: energy,
            "Basic Materials": basicMaterials,
            Industrials: industrials,
            Technology: technology,
            "Consumer Discretionary": consumer,
            Telecommunications: telecommunications,
            Utilities: utilities,
            "Consumer Staples": staples,
            "Health Care": healthCare,
            "Real Estate": realState,
            Cash: cash,
          },
          common_geography_target: othersData,
        };
        // console.log(">asjdhkhakshd", payload);
        const res = await AdminApi.setTargetSettings(payload);

        if (res?.status === 200 || res?.status === 201) {
          showTost({
            show: true,
            text: "Target value has been updated.",
            type: "success",
          });
          fetchData();
        } else {
          showTost({
            show: true,
            text: "Error! Please try again or later.",
            type: "error",
          });
          setLoading(false);
        }
        setdisabled(true);
      }
    };

    return (
      <div>
        <Grid
          container
          columnSpacing={{ xs: 8, md: 12 }}
          rowSpacing={{ xs: 3, md: 5 }}
          columns={{ xs: 12, sm: 24, md: 24 }}
          style={{ alignItems: "center" }}
        >
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Financials"
              className={styles.textField}
              value={financials}
              onChange={(e) => setfinancials(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Energy"
              className={styles.textField}
              value={energy}
              onChange={(e) => setenergy(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Basic Materials"
              className={styles.textField}
              value={basicMaterials}
              onChange={(e) => setbasicMaterials(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Industrials"
              className={styles.textField}
              value={industrials}
              onChange={(e) => setindustrials(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Technology"
              className={styles.textField}
              value={technology}
              onChange={(e) => settechnology(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Consumer Discretionary"
              className={styles.textField}
              value={consumer}
              onChange={(e) => setconsumer(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Telecommunications"
              className={styles.textField}
              value={telecommunications}
              onChange={(e) => settelecommunications(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Utilities"
              className={styles.textField}
              value={utilities}
              onChange={(e) => setutilities(e.target.value)}
            />
          </Grid>{" "}
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Consumer Staples"
              className={styles.textField}
              value={staples}
              onChange={(e) => setstaples(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Health Care"
              className={styles.textField}
              value={healthCare}
              onChange={(e) => sethealthCare(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Real Estate"
              className={styles.textField}
              value={realState}
              onChange={(e) => setrealState(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <TextField
              disabled={disabled}
              type="number"
              id="outlined-controlled"
              label="Cash"
              className={styles.textField}
              value={cash}
              onChange={(e) => setcash(e.target.value)}
            />
          </Grid>
        </Grid>
        <div className={styles.buttonFlex}>
          <BlueBgButton
            title={disabled ? "Edit" : "Save"}
            onClick={handleClick}
            customStyle={{
              paddingLeft: 40,
              paddingRight: 40,
              marginRight: 20,
            }}
          />
          <WhiteBgButton
            title={"Back to List"}
            onClick={() => navigate(`/admin/users`)}
          />
        </div>
      </div>
    );
  }
);

export default Industry;
