import React, { memo } from "react";
import styles from "./styles.module.scss";
import { Stack } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";

const PredictionGraph = memo(
  ({ futureValue, year, futureValueWithoutDeposit, height }) => {
    return (
      <div>
        <Stack
          direction="row"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.title}>Prediction Graph</div>
          <Stack direction="row" spacing={2} style={{ alignItems: "center" }}>
            <div className={styles.yellowBox} />
            <p className={styles.des}>No Additional Contributions</p>
            <div className={styles.blueBox} />
            <p className={styles.des}>Current Contributions</p>
          </Stack>
        </Stack>

        <div style={{ height: height ?? 400, width: "100%" }}>
          {futureValue?.length > 0 && (
            <LineChart
              series={[
                {
                  data: futureValue,
                  color: "#3F8EA3",
                  valueFormatter: (v) => Number(v).toLocaleString(),
                },
                {
                  data: futureValueWithoutDeposit,
                  color: "#FBCD07",
                  valueFormatter: (v) => Number(v).toLocaleString(),
                },
              ]}
              xAxis={[{ scaleType: "point", data: year }]}
              margin={{
                left:
                  futureValue[futureValue.length - 1] > 100000 &&
                  futureValue[futureValue.length - 1] < 1000000
                    ? 60
                    : futureValue[futureValue.length - 1] > 1000000 &&
                      futureValue[futureValue.length - 1] < 10000000
                    ? 70
                    : futureValue[futureValue.length - 1] > 10000000 &&
                      futureValue[futureValue.length - 1] < 100000000
                    ? 100
                    : futureValue[futureValue.length - 1] > 100000000 &&
                      futureValue[futureValue.length - 1] < 1000000000
                    ? 120
                    : futureValue[futureValue.length - 1] > 1000000000
                    ? 150
                    : 50,
              }}
            />
          )}
        </div>
      </div>
    );
  }
);
export default PredictionGraph;
