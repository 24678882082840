import React, { memo } from "react";
import styles from "../userLists/styles.module.scss";
import { getColors } from "../../utills";
import { Avatar, Grid, Stack } from "@mui/material";
import { Start } from "../../assets/svg";
import { useNavigate } from "react-router-dom";
import CheckCircle from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const TickerView = memo(({ data, loading }) => {
  const navigate = useNavigate();
  if (loading) return <div />;

  if (data?.length > 0) {
    return data?.map((item, index) => (
      <div className={styles.container} key={index}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 12, md: 24 }}
          style={{ alignItems: "center" }}
        >
          <Grid item xs={3} sm={4} md={5}>
            <Stack direction="row" style={{ alignItems: "center" }}>
              <Avatar sx={{ bgcolor: getColors(index) }}>
                <p className={styles.avatar}>{item?.name?.charAt(0)}</p>
              </Avatar>
              <div style={{ paddingLeft: 15 }}>
                <p className={styles.name} style={{ paddingBottom: 3 }}>
                  {item?.name}
                </p>
              </div>
            </Stack>
          </Grid>

          <Grid item xs={3} sm={4} md={5}>
            <p className={styles.name}>Ticker Type</p>
            <p className={styles.email}>{item?.is_etf ? "ETF" : "Stock"}</p>
          </Grid>

          <Grid item xs={3} sm={4} md={5}>
            <p className={styles.name}>Asset Class</p>
            <p className={styles.email}>
              {item?.asset_class === "-"
                ? "Equity"
                : item?.asset_class === "Bond-Equity"
                ? "Bond"
                : item?.asset_class}
            </p>
          </Grid>

          <Grid item xs={3} sm={4} md={5}>
            <p className={styles.name}>Is Active</p>

            {item?.status ? (
              <CheckCircle color="secondary" />
            ) : (
              <HighlightOffIcon color="error" />
            )}
          </Grid>

          <Grid item xs={3} sm={4} md={3}>
            <div onClick={() => navigate(`/admin/ticker-list/${item?.name}`)}>
              <Stack
                direction="row"
                className={styles.start}
                style={{ height: 40, width: 160 }}
              >
                <p>View Detail</p>
                <Start />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </div>
    ));
  }
  return <div className={styles.noData}>No Data Found!</div>;
});
