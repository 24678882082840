import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Loader from "../../components/loading/Loader";
import ShowToast from "../../components/ShowToast";
import Header from "../../components/header";
import { Stack } from "@mui/material";
import Geography from "./Geography";
import Industry from "./Industry";
import AdminApi from "../../api/AdminApi";
import { useDispatch } from "react-redux";
import { setTargetData } from "../../store/slices/userSlice";

const TargetSettings = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toast, showTost] = useState({});
  const [selectedTab, setselectedTab] = useState(0);
  const [data, setdata] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const res = await AdminApi.getTargetSettings();
    if (res?.status === 200) {
      setdata(res?.data);
      dispatch(setTargetData(res?.data));
    } else {
      showTost({
        show: true,
        text: "Error! Please try again or later.",
        type: "error",
      });
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <ShowToast toast={toast} showTost={showTost} />
      <Header />
      <p className={styles.name}>Target Settings</p>

      <Stack direction="row" style={{ marginBottom: 50 }}>
        <div
          className={
            selectedTab === 0 ? styles.balance : styles.balanceInactive
          }
          onClick={() => setselectedTab(0)}
        >
          Geography
        </div>
        <div
          className={
            selectedTab === 1 ? styles.allocation : styles.allocationInactive
          }
          onClick={() => setselectedTab(1)}
        >
          Industry
        </div>
      </Stack>

      {selectedTab === 0 && data?.common_geography_target && (
        <Geography
          data={data?.common_geography_target}
          othersData={data?.common_sector_target}
          fetchData={fetchData}
          setLoading={setLoading}
          showTost={showTost}
        />
      )}
      {selectedTab === 1 && data?.common_sector_target && (
        <Industry
          data={data?.common_sector_target}
          othersData={data?.common_geography_target}
          fetchData={fetchData}
          setLoading={setLoading}
          showTost={showTost}
        />
      )}
    </div>
  );
};

export default TargetSettings;
