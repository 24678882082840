import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStepVisited } from "../store/slices/userSlice";

const steps = [
  "Input",
  "ETFS",
  "Geographical Exposure",
  "Sector Breakdown",
  "Future Value Calculator",
  "Notes",
];

const StepperProgress = ({ step, addExtraFunc = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.userData);
  const stepVisited = state?.stepVisited ?? [];

  React.useEffect(() => {
    if (!stepVisited.includes(step)) {
      const arr = [...stepVisited, step];
      dispatch(setStepVisited(arr));
    }
  }, []);

  const navigateHandle = (e) => {
    if (stepVisited.includes(e)) {
      addExtraFunc();
      switch (e) {
        case 0:
          navigate(`/admin/input?form_id=${state?.userInfo._id}`);
          break;
        case 1:
          navigate(`/admin/etfs?form_id=${state?.userInfo._id}`);
          break;
        case 2:
          navigate(
            `/admin/geographical-exposure?form_id=${state?.userInfo._id}`
          );
          break;
        case 3:
          navigate(`/admin/sector-breakdown?form_id=${state?.userInfo._id}`);
          break;
        case 4:
          navigate(
            `/admin/future-value-calculator?form_id=${state?.userInfo._id}`
          );
          break;
        case 5:
          navigate(`/admin/bridgets-note?form_id=${state?.userInfo._id}`);
          break;
        default:
          navigate("/users");
      }
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label, key) => (
          <Step
            key={key}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#3F8EA3",
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "#000",
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: "#3F8EA3",
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "#3F8EA3",
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "#fff",
              },
            }}
          >
            <StepLabel
              style={{
                color: "#3F8EA3",
                cursor: stepVisited.includes(key) ? "pointer" : "not-allowed",
              }}
              onClick={() => navigateHandle(key)}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperProgress;
