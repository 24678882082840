import React from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/bigLogo.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { ArrowDown } from "../../assets/svg";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import AddTask from "@mui/icons-material/AddTask";
import Logout from "@mui/icons-material/Logout";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    navigate("/admin");
  };

  return (
    <div className={styles.container}>
      <img
        src={logo}
        height={75}
        width={112}
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/admin/users")}
      />

      <Stack direction="row" spacing={2} style={{ alignItems: "center" }}>
        <Avatar sx={{ bgcolor: "#3F8EA3", height: 50, width: 50, margin: 0 }}>
          A
        </Avatar>

        <p className={styles.userTitle}>Admin</p>

        <div
          style={{ cursor: "pointer", marginTop: 5, marginLeft: 12 }}
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <ArrowDown />
        </div>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => navigate("/admin/ticker-list")}>
            <ListItemIcon>
              <AddTask fontSize="small" />
            </ListItemIcon>
            Ticker List
          </MenuItem>
          <MenuItem onClick={() => navigate("/admin/target-settings")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Target Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Stack>
    </div>
  );
};

export default Header;
