import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const generatePDF = (
  name,
  contentRef,
  contentRef2,
  contentRef3,
  contentRef4,
  contentRef5,
  contentRef6,
  contentRef7,
  contentRef8,
  contentRef9,
  contentRef10,
  contentRef11,
  contentRef12,
  contentRef13,
  contentRef14,
  setloading,
  setpdfloading,
  setCurrentID = () => null
) => {
  html2canvas(contentRef.current).then((canvas1) => {
    html2canvas(contentRef2.current).then((canvas2) => {
      html2canvas(contentRef3.current).then((canvas3) => {
        html2canvas(contentRef4.current).then((canvas4) => {
          html2canvas(contentRef5.current).then((canvas5) => {
            html2canvas(contentRef6.current).then((canvas6) => {
              html2canvas(contentRef7.current).then((canvas7) => {
                html2canvas(contentRef8.current).then((canvas8) => {
                  html2canvas(contentRef9.current).then((canvas9) => {
                    html2canvas(contentRef10.current).then((canvas10) => {
                      html2canvas(contentRef11.current).then((canvas11) => {
                        html2canvas(contentRef12.current).then((canvas12) => {
                          html2canvas(contentRef13.current).then((canvas13) => {
                            html2canvas(contentRef14.current).then(
                              (canvas14) => {
                                const pdf = new jsPDF("p", "mm", "a4", true);
                                const imgWidth = 210; // A4 width in mm

                                const imgHeight1 =
                                  (canvas1.height * imgWidth) / canvas1.width;
                                const imgHeight2 =
                                  (canvas2.height * imgWidth) / canvas2.width;
                                const imgHeight3 =
                                  (canvas3.height * imgWidth) / canvas3.width;
                                const imgHeight4 =
                                  (canvas4.height * imgWidth) / canvas4.width;
                                const imgHeight5 =
                                  (canvas5.height * imgWidth) / canvas5.width;
                                const imgHeight6 =
                                  (canvas6.height * imgWidth) / canvas6.width;
                                const imgHeight7 =
                                  (canvas7.height * imgWidth) / canvas7.width;
                                const imgHeight8 =
                                  (canvas8.height * imgWidth) / canvas8.width;
                                const imgHeight9 =
                                  (canvas9.height * imgWidth) / canvas9.width;
                                const imgHeight10 =
                                  (canvas10.height * imgWidth) / canvas10.width;
                                const imgHeight11 =
                                  (canvas11.height * imgWidth) / canvas11.width;
                                const imgHeight12 =
                                  (canvas12.height * imgWidth) / canvas12.width;
                                const imgHeight13 =
                                  (canvas13.height * imgWidth) / canvas13.width;
                                const imgHeight14 =
                                  (canvas14.height * imgWidth) / canvas14.width;

                                const imgData1 = canvas1.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData3 = canvas3.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData4 = canvas4.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData2 = canvas2.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData5 = canvas5.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData6 = canvas6.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData7 = canvas7.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData8 = canvas8.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData9 = canvas9.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData10 = canvas10.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData11 = canvas11.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData12 = canvas12.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData13 = canvas13.toDataURL(
                                  "image/png",
                                  0.5
                                );
                                const imgData14 = canvas14.toDataURL(
                                  "image/png",
                                  0.5
                                );

                                // Add first content to PDF
                                pdf.addImage(
                                  imgData1,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight1,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData2,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight2,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData3,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight3,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData4,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight4,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData5,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight5,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData6,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight6,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData7,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight7,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData8,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight8,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData9,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight9,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData10,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight10,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData11,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight11,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData12,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight12,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData13,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight13,
                                  undefined,
                                  "FAST"
                                );
                                pdf.addPage();

                                // Add second content to PDF
                                pdf.addImage(
                                  imgData14,
                                  "PNG",
                                  0,
                                  0,
                                  imgWidth,
                                  imgHeight14,
                                  undefined,
                                  "FAST"
                                );

                                setTimeout(() => {
                                  // Save the PDF
                                  pdf.save(`${name} Portfolio Assessment.pdf`);
                                  contentRef.current.style.display = "none";
                                  contentRef2.current.style.display = "none";
                                  contentRef3.current.style.display = "none";
                                  contentRef4.current.style.display = "none";
                                  contentRef5.current.style.display = "none";
                                  contentRef6.current.style.display = "none";
                                  contentRef7.current.style.display = "none";
                                  contentRef8.current.style.display = "none";
                                  contentRef9.current.style.display = "none";
                                  contentRef10.current.style.display = "none";
                                  contentRef11.current.style.display = "none";
                                  contentRef12.current.style.display = "none";
                                  contentRef13.current.style.display = "none";
                                  contentRef14.current.style.display = "none";

                                  setloading(false);
                                  setpdfloading(false);
                                  setCurrentID("");
                                }, 3000);
                              }
                            );
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  });
};
