export const canadaAndDualCityzenArr = [
  {
    id: 1001,
    data: [
      {
        id: 1111,
        type: "text",
        header: "Retirement Portfolio Analysis",
        description:
          "Now we'll ask some questions about your accounts, their balances, and asset allocations.",
        isRequired: false,
      },
      {
        id: 1112,
        type: "input",
        inputType: "number",
        placeholder: "Enter dollar amount",
        title:
          "Do you have a goal or target balance you want this retirement portfolio to grow to?",
        description:
          "Enter the dollar amount you are hoping this portfolio grows to over time. If you do not have a target balance, you may leave this blank.",
        answer: "",
        isRequired: true,
      },
      {
        id: 1113,
        type: "input",
        inputType: "number",
        placeholder: "Enter remaining years",
        title:
          "How many years until you expect to withdraw from this portfolio?",
        description:
          "Enter the number of years until you plan to make your first withdrawal. For example, if you are 32 years old and will retire at 65, then you would write 33 below.",
        answer: "",
        isRequired: true,
      },
    ],
  },
  //tfsa
  {
    id: 1002,
    data: [
      {
        id: 1114,
        type: "radio",
        title: "Is a TFSA part of this portfolio?",
        description: "TFSA stands for Tax-Free Savings Account.",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 1115,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your TFSA?",
            answer: "",
            isRequired: true,
          },
          {
            id: 1116,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "How much do you contribute to your TFSA per year?",
            description: "Enter the dollar amount. An approximation is ok!",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1003,
    data: [
      {
        id: 1117,
        type: "select",
        title: "Please list your TFSA allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },

  //rrsp
  {
    id: 1004,
    data: [
      {
        id: 1118,
        type: "radio",
        title: "Is a RRSP part of this portfolio?",
        description: "RRSP stands for Registered Retirement Savings Plan.",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 1119,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your RRSP?",
            answer: "",
            isRequired: true,
          },
          {
            id: 1120,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "How much do you contribute to your RRSP per year?",
            description: "Enter the dollar amount. An approximation is ok!",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1005,
    data: [
      {
        id: 1121,
        type: "select",
        title: "Please list your RRSP allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },

  //LIRA
  {
    id: 1006,
    data: [
      {
        id: 1122,
        type: "radio",
        title: "Is a LIRA account part of this portfolio?",
        description:
          "A LIRA is a Locked In Retirement Account and functions a lot like an RRSP but with more stringent contribution and withdrawal rules. LIRAs are created if you leave a job with a pension and choose to take your pension contributions with you.",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 1123,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your LIRA?",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1007,
    data: [
      {
        id: 1124,
        type: "select",
        title: "Please list your LIRA asset allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },
  //unregistered account
  {
    id: 1008,
    data: [
      {
        id: 1125,
        type: "radio",
        title: "Is an unregistered account part of this portfolio?",
        description:
          "An unregistered account is any account without a tax-designation. Basically any investment account that is not a TFSA, RRSP, RRIF, etc. Employer pensions are not unregistered accounts.",
        options: [
          {
            id: 0,
            name: "Yes",
          },
          {
            id: 1,
            name: "No",
          },
        ],
        answer: "",
        isRequired: true,
        additionalData: [
          {
            id: 1126,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title: "What is the balance of your unregistered account?",
            answer: "",
            isRequired: true,
          },
          {
            id: 1127,
            type: "input",
            inputType: "number",
            placeholder: "Enter dollar amount",
            title:
              "How much do you contribute to your unregistered account per year?",
            description: "Enter the dollar amount. An approximation is ok!",
            answer: "",
            isRequired: true,
          },
        ],
      },
    ],
  },
  {
    id: 1009,
    data: [
      {
        id: 1128,
        type: "select",
        title: "Please list your unregistered account allocation in detail.",
        subTitle:
          "For example: Cash - 4.2%, VXC.TO - 15.4%, VCN.TO - 6.9%, AAPL - 23.4%",
        description:
          "Please ensure all securities you want included in the analysis are included, and make sure all numbers are correct!  The total should add up to 100% ",
        isRequired: true,
        answers: [{ name: null, value: null }],
      },
    ],
  },

  //last phase
  {
    id: 1010,
    data: [
      {
        id: 1129,
        type: "textArea",
        title:
          "Do you have any concerns or worries about your existing portfolio allocation?",
        placeholder: "Type here",
        isRequired: true,
        answers: "",
        submitButton: "Go To User Aggreement",
      },
    ],
  },
  {
    id: 1011,
    data: [
      {
        id: 1130,
        type: "radio",
        header: "User Agreement.*",
        description:
          "By checking “I accept” below, you understand that this Portfolio Assessment has been completed using the information you submitted. We are not responsible for any errors due to omission or incorrect information. Any conclusions and projections made with the information you provide are based on historical stock market performance and current security prices, they do not predict or guarantee future investment performance. The Six-Figure Portfolio Assessment tool evaluates your existing portfolio for informational purposes only and does not provide any security recommendations, buy or sell suggestions, or any other trading recommendations. You are responsible for researching and selecting securities, and managing your investment portfolio allocations. While we hope that this tool is valuable in the context of your financial planning, it is not a substitute for professional financial advice via a certified fee-only financial advisor.",
        isRequired: true,
        answer: "",
        submitButton: "Submit Your Profile",
        options: [
          {
            id: 0,
            name: "I Accept",
          },
          {
            id: 1,
            name: "I Don’t Accept",
          },
        ],
      },
    ],
  },
];
