import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styles from "./styles.module.scss";

const Loader = () => {
  return (
    <div className={styles.overlay}>
      <Box className={styles.spinner}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default Loader;
