export const EmailButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M16.418 0.572266H1.58203C0.707942 0.572266 0 1.2846 0 2.15429V11.6465C0 12.5213 0.713145 13.2285 1.58203 13.2285H16.418C17.2847 13.2285 18 12.5243 18 11.6465V2.15429C18 1.28615 17.2949 0.572266 16.418 0.572266ZM16.1964 1.62695C15.8732 1.94845 10.3107 7.48161 10.1187 7.67265C9.81985 7.97148 9.42258 8.13601 9 8.13601C8.57742 8.13601 8.18016 7.97145 7.88035 7.67167C7.75118 7.54317 2.25011 2.07111 1.80359 1.62695H16.1964ZM1.05469 11.4318V2.36963L5.61227 6.90319L1.05469 11.4318ZM1.80425 12.1738L6.36005 7.64699L7.13556 8.41842C7.63358 8.91644 8.29572 9.1907 9 9.1907C9.70429 9.1907 10.3664 8.91644 10.8635 8.41941L11.64 7.64699L16.1957 12.1738H1.80425ZM16.9453 11.4318L12.3877 6.90319L16.9453 2.36963V11.4318Z"
        fill="white"
      />
    </svg>
  );
};
