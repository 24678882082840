import { InputAdornment, Pagination, Stack, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import AdminApi from "../../api/AdminApi";
import Header from "../../components/header";
import SearchIcon from "@mui/icons-material/Search";
import { UserLists } from "../../components/userLists";
import Loader from "../../components/loading/Loader";
import PDFGenerator from "../pdfScreen/PDFGenerator";
import { generatePDF } from "../../utills/generatePDF";
import ShowToast from "../../components/ShowToast";
import { useDispatch } from "react-redux";
import { setTargetData } from "../../store/slices/userSlice";

const Users = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setdata] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setloading] = useState(false);
  const [pdfloading, setpdfloading] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const [toast, showTost] = useState({});
  const [currentID, setCurrentID] = useState("");

  const contentRef = useRef(null);
  const contentRef2 = useRef(null);
  const contentRef3 = useRef(null);
  const contentRef4 = useRef(null);
  const contentRef5 = useRef(null);
  const contentRef6 = useRef(null);
  const contentRef7 = useRef(null);
  const contentRef8 = useRef(null);
  const contentRef9 = useRef(null);
  const contentRef10 = useRef(null);
  const contentRef11 = useRef(null);
  const contentRef12 = useRef(null);
  const contentRef13 = useRef(null);
  const contentRef14 = useRef(null);

  useEffect(() => {
    if (pdfData?.userInfo) {
      setTimeout(() => {
        handleDownload();
      }, 1000);
    }
  }, [pdfData]);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500);
    return () => {
      clearTimeout(timer1);
    };
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const fetchTargetData = async () => {
      const res = await AdminApi.getTargetSettings();
      if (res?.status === 200) {
        dispatch(setTargetData(res?.data));
      }
    };
    fetchTargetData();
  }, []);

  const fetchData = async () => {
    setloading(true);
    const res = await AdminApi.getUsers({ page: page, size: 6, q: search });
    console.log("data===========>>>", res);
    if (res?.status === 200) {
      setdata(res.data);
    }
    setloading(false);
  };

  const generatePdfData = async (id) => {
    setCurrentID(id);
    if (currentID !== id) {
      const res = await AdminApi.getPdfData(id);
      if (res?.status === 200) {
        setPdfData(res?.data?.data);
      } else {
        showTost({
          show: true,
          text: "PDF not found!",
          type: "error",
        });
      }
    }
  };

  const handleDownload = () => {
    setpdfloading(true);
    contentRef.current.style.display = "block";
    contentRef2.current.style.display = "block";
    contentRef3.current.style.display = "block";
    contentRef4.current.style.display = "block";
    contentRef5.current.style.display = "block";
    contentRef6.current.style.display = "block";
    contentRef7.current.style.display = "block";
    contentRef8.current.style.display = "block";
    contentRef9.current.style.display = "block";
    contentRef10.current.style.display = "block";
    contentRef11.current.style.display = "block";
    contentRef12.current.style.display = "block";
    contentRef13.current.style.display = "block";
    contentRef14.current.style.display = "block";

    setTimeout(() => {
      generatePDF(
        pdfData?.userInfo?.client_name ?? "",
        contentRef,
        contentRef2,
        contentRef3,
        contentRef4,
        contentRef5,
        contentRef6,
        contentRef7,
        contentRef8,
        contentRef9,
        contentRef10,
        contentRef11,
        contentRef12,
        contentRef13,
        contentRef14,
        setloading,
        setpdfloading,
        setCurrentID
      );
    }, 3000);
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}

      <ShowToast toast={toast} showTost={showTost} />

      <Header />

      <Stack
        direction="row"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className={styles.userList}> User List</p>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search User by Email..."
          style={{ backgroundColor: "#fff", width: "25%" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <UserLists
        props={data}
        loading={loading}
        handleDownload={generatePdfData}
      />

      <div className={styles.parent}>
        <div className={styles.child}>
          <Pagination
            count={
              data?.metadata?.totalCount
                ? Math.ceil(data?.metadata?.totalCount / 6)
                : 0
            }
            page={page}
            onChange={(_, e) => setPage(e)}
            variant="outlined"
            color="secondary"
            hidePrevButton
            hideNextButton
          />
        </div>
      </div>

      {pdfloading && <div style={{ marginTop: "90vh" }} />}

      {pdfData?.userInfo && (
        <PDFGenerator
          pdfloading={pdfloading}
          userInfo={pdfData?.userInfo}
          futureGraphData={pdfData?.futureGraphData}
          noteText={pdfData?.noteText}
          summary={pdfData?.summary}
          geographicalPieData={pdfData?.geographicalPieData}
          sectorPieData={pdfData?.sectorPieData}
          contentRef={contentRef}
          contentRef2={contentRef2}
          contentRef3={contentRef3}
          contentRef4={contentRef4}
          contentRef5={contentRef5}
          contentRef6={contentRef6}
          contentRef7={contentRef7}
          contentRef8={contentRef8}
          contentRef9={contentRef9}
          contentRef10={contentRef10}
          contentRef11={contentRef11}
          contentRef12={contentRef12}
          contentRef13={contentRef13}
          contentRef14={contentRef14}
        />
      )}
    </div>
  );
};

export default Users;
