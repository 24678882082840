import React, { Fragment, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginScreen from "../../components/login";
import Loader from "../../components/loading/Loader";
import AdminApi from "../../api/AdminApi";
import { storeData } from "../../utills";
import { loginAction } from "../../store/slices/userSlice";
import ShowToast from "../../components/ShowToast";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [email, setemail] = useState("");
  const [errorMsg1, setErrorMsg1] = useState("");
  const [password, setPasword] = useState("");
  const [errorMsg2, setErrorMsg2] = useState("");
  const [toast, showTost] = useState({});

  // console.log("kkkkkk", userData);

  const handleSubmit = useCallback(async () => {
    if (email === "") {
      setErrorMsg1("Please enter your email!");
      return;
    } else if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg1("Email is not valid!");
      return;
    } else if (password === "") {
      setErrorMsg2("Please enter your password!");
      return;
    } else {
      setloading(true);
      const res = await AdminApi.adminLogin({
        email: email,
        password: password,
      });
      if (res?.status === 200 || res?.status === 201) {
        storeData("token", res.data.token);
        storeData("refreshToken", res.data.refresh_token);
        dispatch(loginAction({ ...res.data }));
        setloading(false);
        navigate("/admin/users");
      } else {
        setErrorMsg2(res?.response?.data?.message ?? "Invalid User Data!");
        setloading(false);
        showTost({
          show: true,
          text: res?.response?.data?.message,
          type: "error",
        });
      }
    }
  }, [password, email]);

  return (
    <Fragment>
      {loading && <Loader />}
      <ShowToast toast={toast} showTost={showTost} />
      <LoginScreen
        title={"Sign In"}
        email={email}
        setemail={setemail}
        setErrorMsg1={setErrorMsg1}
        errorMsg1={errorMsg1}
        password={password}
        setPasword={setPasword}
        errorMsg2={errorMsg2}
        setErrorMsg2={setErrorMsg2}
        handleSubmit={handleSubmit}
        fromAdmin={true}
      />
    </Fragment>
  );
};

export default Admin;
