import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tickerData: {},
};

export const tickerSlice = createSlice({
  name: "tickerData",
  initialState,
  reducers: {
    setTickerDetails: (state, action) => {
      state.tickerData = action.payload;
    },
  },
});

export const { setTickerDetails } = tickerSlice.actions;

export default tickerSlice.reducer;
