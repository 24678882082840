import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function ShowToast({ toast, showTost }) {
  const { show, text, type } = toast;
  const handleClose = () => {
    showTost({ show: false });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={show ?? false}
        autoHideDuration={3000}
        onClose={handleClose}
        message={text}
      >
        <Alert
          onClose={handleClose}
          severity={type ?? ""}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toast?.text}
        </Alert>
      </Snackbar>
    </div>
  );
}
