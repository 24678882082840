import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import Loader from "../../components/loading/Loader";
import Header from "../../components/header";
import ShowToast from "../../components/ShowToast";
import { InputAdornment, Pagination, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IconWithTextButton } from "../../components/buttons";
import { TickerView } from "../../components/tickers";
import AdminApi from "../../api/AdminApi";
import { useNavigate } from "react-router-dom";

const TickerList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setdata] = useState(null);
  const [search, setSearch] = useState("");
  const [toast, showTost] = useState({});

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500);
    return () => {
      clearTimeout(timer1);
    };
  }, [search]);

  const fetchData = async () => {
    setLoading(true);
    const res = await AdminApi.getTickerList({
      page: page,
      size: 6,
      name: search,
    });
    if (res?.status === 200) {
      setdata(res?.data);
      setLoading(false);
    } else {
      showTost({
        show: true,
        text: "Unable to get data! Please try again or later.",
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}

      <ShowToast toast={toast} showTost={showTost} />

      <Header />

      <div className={styles.flex}>
        <p className={styles.userList}> Ticker List</p>
        <Stack direction="row" style={{ alignItems: "center" }}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search by ticker name"
            className={styles.textField}
            value={search}
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <IconWithTextButton
            title={"Add New"}
            onClick={() => navigate(`/admin/ticker-list/add-ticker`)}
          />
        </Stack>
      </div>

      <TickerView data={data?.data} loading={loading} />

      <div className={styles.parent}>
        <div className={styles.child}>
          <Pagination
            count={
              data?.metadata?.totalCount
                ? Math.ceil(data?.metadata?.totalCount / 6)
                : 0
            }
            page={page}
            onChange={(_, e) => setPage(e)}
            variant="outlined"
            color="secondary"
            hidePrevButton
            hideNextButton
          />
        </div>
      </div>
    </div>
  );
};

export default TickerList;
