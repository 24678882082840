import React from "react";
import styles from "./styles.module.scss";
import { Grid, Stack } from "@mui/material";
import canada from "../../assets/canada.png";
import usa from "../../assets/usa.png";

export const SummaryDetails = ({ data, fullwidth = false }) => {
  return (
    <div className={fullwidth ? styles.mid2 : styles.mid}>
      <Grid
        container
        spacing={{ xs: 3, md: 3 }}
        columns={{ xs: 24, sm: 24, md: 24 }}
        style={{ alignItems: "center" }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <p className={fullwidth ? styles.text2 : styles.text}>
            Tax Residency
          </p>
          <Stack direction="row" style={{ alignItems: "center" }}>
            <img
              src={data?.tax_residency === "CANADIAN" ? canada : usa}
              height={18}
              width={26}
              alt="n/a"
            />
            <p
              className={fullwidth ? styles.des2 : styles.des}
              style={{ paddingLeft: 10, paddingBottom: 0 }}
            >
              {data?.tax_residency === "CANADIAN"
                ? "Canada"
                : data?.tax_residency === "AMERICAN"
                ? "USA"
                : "Dual Citizen"}
            </p>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <p className={fullwidth ? styles.text2 : styles.text}>
            Target Balance
          </p>
          <p
            className={fullwidth ? styles.des2 : styles.des}
            style={{ paddingBottom: 0 }}
          >
            {data?.target_balance
              ? data?.target_balance.toLocaleString()
              : "N/A"}
          </p>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <p className={fullwidth ? styles.text2 : styles.text}>
            Portfolio Purpose
          </p>
          <p
            className={fullwidth ? styles.des2 : styles.des}
            style={{ paddingBottom: 0 }}
          >
            {data?.portfolio_type === "RETIREMENT"
              ? "Retirement"
              : data?.portfolio_type === "CHILD_EDUCATION"
              ? "Child’s Education"
              : "General Investment"}
          </p>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <p className={fullwidth ? styles.text2 : styles.text}>
            Time until Draw Down
          </p>
          <p
            className={fullwidth ? styles.des2 : styles.des}
            style={{ paddingBottom: 0 }}
          >
            {data?.target_year ?? "N/A"}
          </p>
        </Grid>

        {data?.profiles?.length > 0 &&
          data?.profiles.map((item, i) => (
            <Grid item xs={24} sm={24} md={fullwidth ? 24 : 12} key={i}>
              <div className={styles.midSubContainer}>
                <p
                  className={fullwidth ? styles.text2 : styles.text}
                  style={{
                    paddingLeft: 20,
                    paddingTop: 20,
                  }}
                >
                  Account #{i + 1} {item?.account_name}
                </p>
                <ul style={{ margin: 0 }}>
                  <li
                    className={fullwidth ? styles.des2 : styles.des}
                    style={{ paddingBottom: 10 }}
                  >
                    Balance: $
                    {item?.current_account_balance
                      ? item?.current_account_balance.toLocaleString()
                      : "N/A"}
                  </li>
                  <li
                    className={fullwidth ? styles.des2 : styles.des}
                    style={{ paddingBottom: 10 }}
                  >
                    Annual contributions: $
                    {item?.annual_contribution
                      ? item?.annual_contribution.toLocaleString()
                      : "N/A"}
                  </li>
                  <li className={fullwidth ? styles.des2 : styles.des}>
                    Allocation:{" "}
                    {item?.account_allocations?.length > 0 &&
                      item?.account_allocations.map((res, j) => (
                        <span key={j}>
                          {res?.ticker_name} {res?.percentage}
                          {"% "}
                        </span>
                      ))}
                  </li>
                </ul>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
