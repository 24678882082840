import axios from "axios";
import AdminApi from "./AdminApi";
import { storeData } from "../utills";
import { store } from "../store/store";
import { logoutAction } from "../store/slices/userSlice";

const request = async (config) => {
  const callBack = axios(config);
  return callBack
    .then((response) => {
      console.log("🚀response~", response);
      return callBack;
    })
    .catch(async (error) => {
      console.log("🚀error~", error.response);
      if (error.response == null) {
        return error;
      } else if (error.response.status === 400) {
        const result = await refreshToken();
        if (result !== null) {
          try {
            config.headers = {
              common: {
                Authorization: `Bearer ${result}`,
              },
            };
          } catch (error) {}
          return axios(config);
        } else {
          return axios(config);
        }
      } else if (error.response.status === 403) {
        return error;
      } else if (error.code === "ECONNABORTED") {
        return axios(config);
      } else {
        return error;
      }
    });
};

const refreshToken = async () => {
  let refresh_Token = localStorage.getItem("refreshToken");
  try {
    const res = await AdminApi.refreshToken({
      refresh_token: refresh_Token,
    });
    if (res?.status === 200 || res?.status === 201) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.access_token}`;
      storeData("token", res.data.access_token);
      return res.data.access_token;
    } else {
      console.log("Token has been expired");
      store.dispatch(logoutAction());
      return null;
    }
  } catch (error) {
    console.log("Error in logout from refreshtoken", error);
    store.dispatch(logoutAction());
    return null;
  }
};

export { request };
