import { request } from "./config";

const post = async (url, body) => {
  const config = {
    url,
    baseURL: "https://he4qdamldc.execute-api.ca-central-1.amazonaws.com",
    method: "post",
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const put = async (url, body) => {
  const token = localStorage.getItem("token");
  const header = {
    Authorization: `Bearer ${token}`,
  };
  const config = {
    url,
    baseURL: "https://he4qdamldc.execute-api.ca-central-1.amazonaws.com",
    method: "put",
    data: body,
    headers: header,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const get = async (url, params = {}) => {
  const token = await localStorage.getItem("token");
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  const config = {
    url,
    baseURL: "https://he4qdamldc.execute-api.ca-central-1.amazonaws.com",
    method: "get", // default
    headers: header,
    params: params,
    timeout: 30000, // default is `0` (no timeout)// 30 sec
    maxRedirects: 2, // default
  };
  return request(config);
};

const AdminHttpClient = {
  post,
  put,
  get,
};

export default AdminHttpClient;
