import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Header from "../../components/header";
import StepperProgress from "../../components/Stepper";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { BlueBgButton, WhiteBgButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import AdminApi from "../../api/AdminApi";
import ShowToast from "../../components/ShowToast";
import Loader from "../../components/loading/Loader";
import {
  setBridgetsVisited,
  setIsSubmitted,
} from "../../store/slices/userSlice";

const BridgetsNotes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userData?.userInfo);
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [loading, setloading] = useState(false);
  const [noteText, setnoteText] = useState("");
  const [toast, showTost] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      const res = await AdminApi.getNotes(userInfo?._id);
      if (
        res?.status === 200 &&
        typeof window !== "undefined" &&
        res?.data?.notes?.length > 0
      ) {
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(res?.data?.notes)
        );
        const newEditorState = EditorState.createWithContent(contentState);
        seteditorState(newEditorState);
        setnoteText(res?.data?.notes);
      }
      setloading(false);
    };
    userInfo?._id && fetchData();
  }, [userInfo?._id]);

  useEffect(() => {
    let rawContentState = convertToRaw(editorState.getCurrentContent());
    let htmlString = draftToHtml(rawContentState);
    setnoteText(htmlString);
  }, [editorState]);

  const generatePdf = async () => {
    await AdminApi.setNotes(userInfo._id, {
      form_id: userInfo._id,
      notes: noteText,
    });
    dispatch(setBridgetsVisited());
  };

  const goToNext = async () => {
    if (noteText.length > 8) {
      setloading(true);
      generatePdf();
      const res = await AdminApi.setComplete({
        form_id: userInfo?._id,
        form_status: "Done",
      });
      if (res?.status === 200) {
        dispatch(setIsSubmitted());
      }
      navigate(`/admin/pdf?form_id=${userInfo?._id}`);
    } else {
      showTost({
        show: true,
        text: "Summary can't be empty!",
        type: "error",
      });
    }
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <ShowToast toast={toast} showTost={showTost} />
      <Header />
      <p className={styles.name}>{userInfo?.client_name}</p>
      <StepperProgress step={5} addExtraFunc={generatePdf} />

      <div className={styles.editor}>
        <p className={styles.des}>Type your assessment summary here</p>

        <Editor
          editorState={editorState}
          toolbarClassName={styles.toolbarClassName}
          wrapperClassName={styles.wrapperClassName}
          editorClassName={styles.editorClassName}
          onEditorStateChange={seteditorState}
          placeholder="Write your summary here..."
          toolbar={{
            options: ["inline", "fontSize", "list", "textAlign"],

            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },
          }}
        />

        <div className={styles.bottomContainer}>
          <WhiteBgButton
            title={"Back To Future Value Calculator"}
            onClick={() => {
              generatePdf();
              navigate(
                `/admin/future-value-calculator?form_id=${userInfo?._id}`
              );
            }}
            customStyle={{ paddingLeft: 20, paddingRight: 20, marginRight: 20 }}
          />

          <BlueBgButton title={"Generate PDF"} onClick={goToNext} />
        </div>
      </div>
    </div>
  );
};

export default BridgetsNotes;
