import { combineReducers } from "@reduxjs/toolkit";
import storage from "../storage";
import userSlice from "./userSlice";
import tickerSlice from "./tickerSlice";

const allReducers = combineReducers({
  userData: userSlice,
  tickerData: tickerSlice,
});

const reducers = (state, action) => {
  if (action.type === "userData/logoutAction") {
    storage.removeItem("persist:root");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("root");
    localStorage.clear();
    // window.location.pathname = "/admin";
    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

export default reducers;
