import React from "react";
import styles from "./styles.module.scss";

const BalanceContribution = ({ data }) => {
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <th>Item</th>
          <th>Balance</th>
          <th>Contribution</th>
          <th>Breakdown</th>
        </tr>
        {data?.length > 0
          ? data.map((item, i) => (
              <tr key={i}>
                <td>{item.item}</td>
                <td>${item.balance ? item.balance.toLocaleString() : 0}</td>
                <td>
                  ${item.contribution ? item.contribution.toLocaleString() : 0}
                </td>
                <td>{item.breakdown}</td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );
};

const Allocation = ({ data }) => {
  // console.log(">>>>>>>>>", data);
  if (data?.length > 0) {
    return <div></div>;
  }

  return (
    <>
      <table className={styles.table}>
        <tbody>
          <tr>
            {data?.headers?.length > 0 &&
              data.headers.map((header, i) => <th key={i}>{header}</th>)}
            <th>Total</th>
          </tr>
          {data?.t_rows?.length > 0
            ? data.t_rows.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.item}</td>
                    <td>{item.ticker}</td>
                    {item?.TFSA === 0 && <td>0%</td>}
                    {item?.TFSA !== null &&
                      item?.TFSA !== undefined &&
                      item?.TFSA !== 0 && <td>{item?.TFSA}%</td>}

                    {item?.RRSP === 0 && <td>0%</td>}
                    {item?.RRSP !== null &&
                      item?.RRSP !== undefined &&
                      item?.RRSP !== 0 && <td>{item?.RRSP}%</td>}

                    {item?.LIRA === 0 && <td>0%</td>}
                    {item?.LIRA !== null &&
                      item?.LIRA !== undefined &&
                      item?.LIRA !== 0 && <td>{item?.LIRA}%</td>}

                    {item?.ROTH_IRA === 0 && <td>0%</td>}
                    {item?.ROTH_IRA !== null &&
                      item?.ROTH_IRA !== undefined &&
                      item?.ROTH_IRA !== 0 && <td>{item?.ROTH_IRA}%</td>}

                    {item?.K401 === 0 && <td>0%</td>}
                    {item?.K401 !== null &&
                      item?.K401 !== undefined &&
                      item?.K401 !== 0 && <td>{item?.K401}%</td>}

                    {item?.RESP === 0 && <td>0%</td>}
                    {item?.RESP !== null &&
                      item?.RESP !== undefined &&
                      item?.RESP !== 0 && <td>{item?.RESP}%</td>}

                    {item?.PLAN_529 === 0 && <td>0%</td>}
                    {item?.PLAN_529 !== null &&
                      item?.PLAN_529 !== undefined &&
                      item?.PLAN_529 !== 0 && <td>{item?.PLAN_529}%</td>}

                    {item?.UNREGISTERED_ACCOUNT === 0 && <td>0%</td>}
                    {item?.UNREGISTERED_ACCOUNT !== null &&
                      item?.UNREGISTERED_ACCOUNT !== undefined &&
                      item?.UNREGISTERED_ACCOUNT !== 0 && (
                        <td>{item?.UNREGISTERED_ACCOUNT}%</td>
                      )}

                    {item?.total === 0 && <td>0%</td>}
                    {item?.total !== null &&
                      item?.total !== undefined &&
                      item?.total !== 0 && <td>{item?.total}%</td>}
                  </tr>
                );
              })
            : null}
          <tr>
            <td>Total</td>
            <td></td>
            {Array.from(Array(data?.headers?.length - 1)).map((_, b) => (
              <td key={b}>100%</td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export { BalanceContribution, Allocation };
