export const Start = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="9"
      viewBox="0 0 22 9"
      fill="none"
    >
      <path
        d="M0 4.5C0 4.67082 0.0681722 4.83464 0.189519 4.95543C0.310866 5.07622 0.475448 5.14408 0.647059 5.14408H19.7909L17.0138 7.90847C16.8959 8.02995 16.8307 8.19264 16.8322 8.36152C16.8336 8.53039 16.9017 8.69194 17.0216 8.81135C17.1416 8.93077 17.3039 8.99851 17.4736 8.99998C17.6432 9.00144 17.8067 8.93652 17.9287 8.8192L21.8111 4.95472C21.8709 4.89547 21.9185 4.82502 21.9509 4.74742C21.9833 4.66983 22 4.58662 22 4.50258V4.5C22 4.41627 21.9832 4.33254 21.9495 4.25396C21.9173 4.17588 21.8698 4.10494 21.8098 4.04528L17.9274 0.180801C17.8054 0.0634765 17.6419 -0.00144314 17.4723 2.43479e-05C17.3026 0.00149183 17.1403 0.0692289 17.0204 0.188646C16.9004 0.308064 16.8323 0.469608 16.8309 0.638483C16.8294 0.807359 16.8946 0.970055 17.0125 1.09153L19.7909 3.85592H0.647059C0.475448 3.85592 0.310866 3.92378 0.189519 4.04457C0.0681722 4.16536 0 4.32918 0 4.5Z"
        fill="white"
      />
    </svg>
  );
};
