import styles from "./styles.module.scss";

export const WhiteBgButton = ({ title, onClick, customStyle }) => {
  return (
    <div
      className={styles.whiteBg}
      style={customStyle}
      onClick={onClick}
      type="submit"
    >
      {title}
    </div>
  );
};
