import { Stack } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";
import { Start } from "../../assets/svg";

export const IconWithTextButton = ({ title, onClick, customStyle }) => {
  return (
    <Stack
      direction="row"
      className={styles.start}
      style={customStyle}
      onClick={onClick}
    >
      {title === "Add New" && <p style={{ marginBottom: 20 }}>+</p>}
      <p>{title}</p>
      {title !== "Add New" && <Start />}
    </Stack>
  );
};
