import React, { Fragment, memo } from "react";
import styles from "./styles.module.scss";
import { Grid, Stack } from "@mui/material";
import { PieChart } from "@mui/x-charts";

const PieChartScreen = memo(({ pieData }) => {
  const filter = pieData.filter((e) => e.value > 0);
  const noData = [
    {
      color: "#f2f2f2",
      label: "No Data",
      value: 100,
    },
  ];

  return (
    <Grid
      container
      spacing={{ xs: 3, md: 3 }}
      columns={{ xs: 24, sm: 24, md: 24 }}
      style={{
        alignItems: "center",
        // marginTop: 10,
        // marginBottom: 50,
      }}
    >
      <Grid item xs={24} sm={10} md={10}>
        <PieChart
          series={[
            {
              data: filter?.length > 0 ? pieData : noData,
              valueFormatter: (v, { dataIndex }) => {
                const { value } = pieData[dataIndex];
                return `${filter?.length > 0 ? value : 0}%`;
              },
            },
          ]}
          height={window.innerHeight > 900 ? 600 : 400}
          width={window.innerHeight > 900 ? 600 : 400}
          slotProps={{
            legend: { hidden: true },
          }}
        />
      </Grid>
      <Grid item xs={24} sm={14} md={14}>
        <Grid
          container
          // columnSpacing={{ xs: 5, sm: 5, md: 5 }}
          columns={{ xs: 24, sm: 24, md: 24 }}
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {pieData.map((item, i) => (
            <Fragment key={i}>
              <Grid item xs={18} sm={9} md={9}>
                <Stack
                  direction="row"
                  style={{
                    alignItems: "center",
                  }}
                  key={i}
                >
                  <Stack direction="row" style={{ alignItems: "center" }}>
                    <div
                      className={styles.round}
                      style={{
                        backgroundColor: item.color,
                      }}
                    />
                    <p>{item.label}</p>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={6} sm={3} md={3}>
                <p>{item.value}%</p>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default PieChartScreen;
